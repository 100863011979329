import React, { Component } from "react";
import PubsContext from "./Pubs";

export default class PubsProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPub: null,
    };
  }

  render() {
    return (
      <PubsContext.Provider
        value={{
          newPub: this.state.newPub,
          setNewPub: (newPub) => {
            this.setState({
              newPub,
            });
          },
        }}
      >
        {this.props.children}
      </PubsContext.Provider>
    );
  }
}
