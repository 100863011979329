import React, { Component } from "react";

import NewsContext from "./News";

export default class NewsProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newEvent: null,
    };
  }

  render() {
    return (
      <NewsContext.Provider
        value={{
          newEvent: this.state.newEvent,
          setNewEvent: (newEvent) => {
            this.setState({
              newEvent,
            });
          },
        }}
      >
        {this.props.children}
      </NewsContext.Provider>
    );
  }
}
