import axios from "axios";

let api = axios.create({
  baseURL: "https://public-procurement-service.onrender.com/",
  // baseURL: "http://192.168.1.12:3000",
});

export const Authentication = async (payload) =>
  await api.post("/users/login", payload);

export const NewEvent = async (payload, access_token) => {
  api.defaults.headers["Authorization"] = `Bearer ${access_token}`;
  return await api.post(`/events/new`, payload);
};

export const GetEvents = async (payload) => {
  return await api.post(`/events/list`, payload);
};

export const UpdateEvent = async (code, payload, access_token) => {
  api.defaults.headers["Authorization"] = `Bearer ${access_token}`;
  return await api.patch(`/events/${code}`, payload);
};

export const GetProcurementsPlans = async (payload) =>
  await api.post(`/procurement-plan/list`, payload);

export const NewProcurementPlan = async (payload, access_token) => {
  api.defaults.headers["Authorization"] = `Bearer ${access_token}`;
  return await api.post(`/procurement-plan/new`, payload);
};

export const NewProvisionalNoticeAward = async (payload, access_token) => {
  api.defaults.headers["Authorization"] = `Bearer ${access_token}`;
  return await api.post(`/provisional-notice-award/new`, payload);
};

export const GetAwareNotices = async (payload) =>
  await api.post(`/provisional-notice-award/list`, payload);

export const GetRecapData = async () => await api.get(`/recap-data`);

export const GetGeneralNotices = async (payload) =>
  await api.post(`/general-notice/list`, payload);

export const NewGeneralNotice = async (payload, access_token) => {
  api.defaults.headers["Authorization"] = `Bearer ${access_token}`;
  return await api.post(`/general-notice/new`, payload);
};

export const RegisterToNewsLetter = async (payload) =>
  await api.post("/users/register", payload);

export const UpdateProcurementPlan = async (code, payload, access_token) => {
  api.defaults.headers["Authorization"] = `Bearer ${access_token}`;
  return await api.patch(`/procurement-plan/${code}`, payload);
};

export const UpdateAwareNotice = async (code, payload, access_token) => {
  api.defaults.headers["Authorization"] = `Bearer ${access_token}`;
  return await api.patch(`/provisional-notice-award/${code}`, payload);
};

export const UpdateGeneralNotice = async (code, payload, access_token) => {
  api.defaults.headers["Authorization"] = `Bearer ${access_token}`;
  return await api.patch(`/general-notice/${code}`, payload);
};

export const GetOpportunities = async (payload) => {
  return await api.post(`/opportunities/list`, payload);
};

export const NewOpportunity = async (payload, access_token) => {
  api.defaults.headers["Authorization"] = `Bearer ${access_token}`;
  return await api.post(`/opportunities/new`, payload);
};

export const UpdateOpportunity = async (code, payload, access_token) => {
  api.defaults.headers["Authorization"] = `Bearer ${access_token}`;
  return await api.patch(`/opportunities/${code}`, payload);
};

export const GetPartners = async (payload) => {
  return await api.post(`/partners/list`, payload);
};


export const NewPartner = async (payload, access_token) => {
  api.defaults.headers["Authorization"] = `Bearer ${access_token}`;
  return await api.post(`/partners/new`, payload);
};

export const UpdatePartner = async (code, payload, access_token) => {
  api.defaults.headers["Authorization"] = `Bearer ${access_token}`;
  return await api.patch(`/partners/${code}`, payload);
};

export const GetUsers = async (payload) => {
  return await api.post(`/users/list`, payload);
};

export const UpdateUser = async (code, payload, access_token) => {
  api.defaults.headers["Authorization"] = `Bearer ${access_token}`;
  return await api.patch(`/users/${code}`, payload);
};

export const GetEventsDetail = async (code) => {
  return await api.get(`/events/${code}`);
};

export const GetOpportunityDetail = async (code) => {
  return await api.get(`/opportunities/${code}`);
};

export const UpdateNews = async (code, payload, access_token) => {
  api.defaults.headers["Authorization"] = `Bearer ${access_token}`;
  return await api.patch(`/events/${code}`, payload);
};

export const SendNewsLetter = async (payload, endpoint = "", access_token) => {
  api.defaults.headers["Authorization"] = `Bearer ${access_token}`;
  return await api.post(`/newsletters/${endpoint}`, payload);
};

export const GetPubs = async (payload) => {
  return await api.post(`/pubs/list`, payload);
};

export const NewPub = async (payload, access_token) => {
  api.defaults.headers["Authorization"] = `Bearer ${access_token}`;
  return await api.post(`/pubs/new`, payload);
};

export const UpdatePub = async (code, payload, access_token) => {
  api.defaults.headers["Authorization"] = `Bearer ${access_token}`;
  return await api.patch(`/pubs/${code}`, payload);
};

export const AddRemovePubOnNewsletter = async (payload, access_token) => {
  api.defaults.headers["Authorization"] = `Bearer ${access_token}`;
  return await api.post(`/pubs/add-on-newsletter`, payload);
};

const apis = {
  Authentication,
  NewEvent,
  GetEvents,
  UpdateEvent,
  GetProcurementsPlans,
  NewProcurementPlan,
  NewProvisionalNoticeAward,
  GetAwareNotices,
  GetRecapData,
  GetGeneralNotices,
  NewGeneralNotice,
  RegisterToNewsLetter,
  UpdateProcurementPlan,
  UpdateAwareNotice,
  UpdateGeneralNotice,
  GetOpportunities,
  NewOpportunity,
  UpdateOpportunity,
  GetPartners,
  NewPartner,
  UpdatePartner,
  GetUsers,
  UpdateUser,
  GetEventsDetail,
  GetOpportunityDetail,
  UpdateNews,
  SendNewsLetter,
  GetPubs,
  NewPub,
  UpdatePub,
  AddRemovePubOnNewsletter,
};

export default apis;
