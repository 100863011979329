import React, { useState } from "react";
import NewsLetter from "../newsletters";
import { RiCloseCircleFill } from "react-icons/ri";
import { WindowsSizeBreakpoints, isMobileOrSmallScreen } from "../../utils";
import { useWindowSize } from "../../utils/useWindowSize";

export default function ModalCard({
  isOpen = false,
  onClose,
  backgroundColor = "#FFF",
  content = null,
  header = null,
  footer = null,
  marginTop = "5vh",
  headerBackgroundColor = "#FFF"
}) {
  const [isMobileDevice, _] = useState(isMobileOrSmallScreen());
  const { width } = useWindowSize();
  if (!isOpen) return null;
  return (
    <div id="myModal" className="modal">
      <div
        className="modal-content"
        style={{
          margin: isMobileDevice || Boolean(width <= WindowsSizeBreakpoints.medium)
          ? `${marginTop} 3vw`
          : `${marginTop} auto`,
          backgroundColor,
          borderColor: "none",
          borderRadius: "0px",
          width: isMobileDevice || Boolean(width <= WindowsSizeBreakpoints.medium)
          ? "100%"
          : "50%",
        }}
      >
        <div className="modal-data">
          <div
            className="header-modal"
            style={{ alignItems: "flex-start", borderBottom: "none", position: "relative", backgroundColor: headerBackgroundColor, height: "fit-content "}}
          >
            <div className="modal-title" style={{ marginLeft: "0px", width: "-webkit-fill-available" }}>
              {header}
            </div>
            <div
              className="close-button"
              onClick={() => onClose()}
              style={
                {
                  position: "absolute",
                  right: "0px"
                }
              }
            >
              <RiCloseCircleFill />
            </div>
          </div>
          <div style={{ overflow: "auto" }}>{content}</div>
          <div className="footer-modal" style={{ justifyContent: "flex-start"}}>{footer}</div>
        </div>
      </div>
    </div>
  );
}

//
