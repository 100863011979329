import React, { useState } from "react";

export default function CustomFilterSearch({
  onSearchTerm,
  placeholder = "#Mot clé (autorité, ref, réalisation)",
  defaultValue = "",
}) {
  const [searchTerm, setSearchTerm] = useState(defaultValue || "");

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      makeSearch();
    }
  };

  const makeSearch = () => {
    try {
      onSearchTerm(searchTerm);
    } catch (error) {}
  };

  return (
    <div style={{ margin: "20px 0px" }}>
      <form className="search-wrapper cf">
        <input
          type="text"
          placeholder={placeholder}
          required
          style={{
            boxShadow: "none",
            backgroundColor: "#F1F3F5",
          }}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <div
          className="button"
          onClick={() => makeSearch()}
          style={{ backgroundColor: "#000", color: "white" }}
        >
          Rechercher
        </div>
      </form>
    </div>
  );
}
