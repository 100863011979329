import React, { useCallback, useState } from "react";
import {
  BodyCardGeneralNoticeContainer,
  CardGeneralNoticeContainer,
  ContentDataCardGeneralNotice,
  Flash,
  FooterCardGeneralNoticeContainer,
  HeaderCardGeneralNoticeContainer,
  InColumnContentDataCardGeneralNotice,
  InLineContentDataCardGeneralNotice,
  LabelCardGeneralNotice,
  ValueCardGeneralNotice,
} from "../../styles/cards";
import {
  formatDateToDDMMYYYY,
  getAtrributionTypeTraduction,
  getRemainingDays,
  truncateText,
} from "../../utils";

export default function CardAwareNotice({ data, onClick }) {
  const [remainingDays, setRemainingDays] = useState(
    getRemainingDays(data.limitDate)
  );

  const getFlashColor = useCallback(() => {
    if (remainingDays < 0) return "#FF1F00";
    if (remainingDays > 5) return "#E1E986";
    if (remainingDays <= 5) return "#F6916C";
  }, [remainingDays]);

  const displayRemainingDay = useCallback(() => {
    if (remainingDays === null || !data.limitDate)
      return <div style={{ backgroundColor: "#E1E986" }}></div>;
    if (remainingDays >= 0) {
      return (
        <Flash
          color={getFlashColor()}
          style={{ borderRadius: "0px", animationName: "flash-card", flexDirection: "column", color: "#FFF" }}
        >
          Dépôt offres
          <b>
            {remainingDays} Jr{remainingDays > 1 ? "s" : ""} restant{remainingDays > 1 ? "s" : ""}
          </b>
          {""}
          {/* &nbsp;restant{remainingDays > 1 ? "s" : ""} */}
        </Flash>
      );
    }
    return (
      <Flash
        color={getFlashColor()}
        style={{ animationName: "none", color: "white", borderRadius: "0px" }}
      >
        <b>Délai dépassé</b> &nbsp;
      </Flash>
    );
  }, [remainingDays]);

  const getAssignee = () => {
    try {
      let value = "";
      if (!data?.lots?.length) return "";
      for (const item of data.lots) {
        value += `${item.nameOfAssignee}, `;
      }
      return value;
    } catch (error) {
      console.log({ error });
      return "";
    }
  };

  return (
    <CardGeneralNoticeContainer
      style={{
        gridTemplateRows: "0fr",
      }}
      onClick={() => onClick(data)}
    >
      <HeaderCardGeneralNoticeContainer
        style={{
          gridTemplateColumns: "1fr 3fr 1fr",
        }}
      >
        <ContentDataCardGeneralNotice
          style={{
            backgroundColor: "#727D1F",
          }}
        >
          <LabelCardGeneralNotice
            style={{
              color: "#FFF",
              fontSize: "7pt",
            }}
          >
            RÉFÉRENCE
          </LabelCardGeneralNotice>
          <ValueCardGeneralNotice
            style={{
              fontSize: "8pt",
              color: "#FFF",
            }}
          >
            {truncateText(data.ref, 10)}
          </ValueCardGeneralNotice>
        </ContentDataCardGeneralNotice>
        <ContentDataCardGeneralNotice
          style={{
            backgroundColor: "#A8B252",
          }}
        >
          <LabelCardGeneralNotice style={{ color: "#FFF" }}>
            AUTORITÉ CONTRACTANTE
          </LabelCardGeneralNotice>
          <ValueCardGeneralNotice style={{ color: "#FFF" }}>
            {truncateText(data.authority, 65)}
          </ValueCardGeneralNotice>
        </ContentDataCardGeneralNotice>
        {/* <ContentDataCardGeneralNotice
          style={{
            backgroundColor: "#E1E986",
          }}
        >
          <LabelCardGeneralNotice
            style={{
              color: "#000",
              fontSize: "7pt",
            }}
          >
            NOMBRE D'OFFRES
          </LabelCardGeneralNotice>
          <ValueCardGeneralNotice
            style={{
              fontSize: "10pt",
              color: "#000",
            }}
          >
            {data.receivedOffers?.toLocaleString('fr-FR')}
          </ValueCardGeneralNotice>
        </ContentDataCardGeneralNotice> */}
        {displayRemainingDay()}
      </HeaderCardGeneralNoticeContainer>
      <BodyCardGeneralNoticeContainer
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          //   alignItems: "center",
          borderBottom: "1px solid #E4EBE8",
          margin: "10px",
        }}
      >
        <InLineContentDataCardGeneralNotice style={{ marginBottom: "20px" }}>
          <InColumnContentDataCardGeneralNotice>
            <div>
              <LabelCardGeneralNotice
                style={{ color: "#000", fontSize: "8pt" }}
              >
                TYPE D'ATTRIBUTION: &nbsp; &nbsp;
              </LabelCardGeneralNotice>
              <ValueCardGeneralNotice style={{ color: "#000" }}>
                {getAtrributionTypeTraduction[data.type]}
              </ValueCardGeneralNotice>
            </div>
            <div>
              <LabelCardGeneralNotice
                style={{ color: "#000", fontSize: "8pt" }}
              >
                NOMBRE D'OFFRES REÇUS: &nbsp; &nbsp;
              </LabelCardGeneralNotice>
              <ValueCardGeneralNotice style={{ color: "#000" }}>
                {data.receivedOffers?.toLocaleString("fr-FR")}
              </ValueCardGeneralNotice>
            </div>
            <div>
              <LabelCardGeneralNotice
                style={{ color: "#000", fontSize: "8pt" }}
              >
                MODE DE PASSATION: &nbsp; &nbsp;
              </LabelCardGeneralNotice>
              <ValueCardGeneralNotice style={{ color: "#000" }}>
                {data.method}
              </ValueCardGeneralNotice>
            </div>
          </InColumnContentDataCardGeneralNotice>
        </InLineContentDataCardGeneralNotice>
        <InLineContentDataCardGeneralNotice style={{ marginBottom: "20px" }}>
          <LabelCardGeneralNotice style={{ color: "#000", fontSize: "8pt" }}>
            ATTRIBUTAIRE{`${data?.lots?.length > 1 ? "S" : ""}`}: &nbsp; &nbsp;
          </LabelCardGeneralNotice>
          <ValueCardGeneralNotice style={{ color: "#000", fontWeight: "bold" }}>
            {/* {data.nameOfAssignee} */}
            {data?.lots?.length ? getAssignee() : data.nameOfAssignee}
          </ValueCardGeneralNotice>
        </InLineContentDataCardGeneralNotice>
        <InLineContentDataCardGeneralNotice
          style={{ flexDirection: "column", alignItems: "unset" }}
        >
          <LabelCardGeneralNotice style={{ color: "#000", fontSize: "8pt" }}>
            DÉNOMINATION: &nbsp; &nbsp;
          </LabelCardGeneralNotice>
          <ValueCardGeneralNotice style={{ color: "#000" }}>
            {data.name}
          </ValueCardGeneralNotice>
        </InLineContentDataCardGeneralNotice>
      </BodyCardGeneralNoticeContainer>
      <FooterCardGeneralNoticeContainer>
        <ContentDataCardGeneralNotice>
          <LabelCardGeneralNotice style={{ color: "#000", fontSize: "8pt" }}>
            DATE DE PUBLICATION
          </LabelCardGeneralNotice>
          <ValueCardGeneralNotice style={{ color: "#000" }}>
            {formatDateToDDMMYYYY(data.publicationDate)}
          </ValueCardGeneralNotice>
        </ContentDataCardGeneralNotice>
        <ContentDataCardGeneralNotice>
          <LabelCardGeneralNotice style={{ color: "#000", fontSize: "8pt" }}>
            LIEU DE PUBLICATION
          </LabelCardGeneralNotice>
          <ValueCardGeneralNotice style={{ color: "#000" }}>
            {truncateText(data.publicationLocation)}
          </ValueCardGeneralNotice>
        </ContentDataCardGeneralNotice>
        {Boolean(data?.lots?.length > 1) ? (
          <ContentDataCardGeneralNotice>
            <LabelCardGeneralNotice style={{ color: "#000", fontSize: "8pt" }}>
              NOMBRE DE LOTS
            </LabelCardGeneralNotice>
            <ValueCardGeneralNotice style={{ color: "#000" }}>
              {data.lots?.length}
            </ValueCardGeneralNotice>
          </ContentDataCardGeneralNotice>
        ) : (
          <ContentDataCardGeneralNotice>
            <LabelCardGeneralNotice style={{ color: "#000", fontSize: "8pt" }}>
              MONTANT DE L'OFFRE
            </LabelCardGeneralNotice>
            <ValueCardGeneralNotice style={{ color: "#000" }}>
              {data?.lots?.length ? data.lots[0].offerAmount?.toLocaleString("fr-FR"): data.offerAmount?.toLocaleString("fr-FR")}
            </ValueCardGeneralNotice>
          </ContentDataCardGeneralNotice>
        )}
      </FooterCardGeneralNoticeContainer>
    </CardGeneralNoticeContainer>
  );
}
