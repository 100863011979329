import React from "react";

export default function CustomPagination({
  page,
  onNextPage,
  onPreviousPage,
  isLoadingData,
  total,
  limit,
  backgroundColor = "#2EAB82"
}) {
  return (
    <div
      className="pagination"
      style={{
        display: isLoadingData ? "none" : "flex",
        marginRight: "0px",
      }}
    >
      <small style={{ fontSize: "10pt", backgroundColor, color: "#FFF" }}>
        <b>{page}</b> / {Math.ceil(total / limit)}
      </small>
      <small
        className={page > 1 ? "side-pagination" : "side-pagination-disabled"}
        onClick={onPreviousPage}
        style={{
          color: page > 1 ? "black" : "#D8D8D8",
        }}
      >
        ❮
      </small>
      <small
        className={
          page < Math.ceil(total / limit)
            ? "side-pagination"
            : "side-pagination-disabled"
        }
        onClick={onNextPage}
        style={{
          color: page < Math.ceil(total / limit) ? "black" : "#D8D8D8",
        }}
      >
        ❯
      </small>
    </div>
  );
}
