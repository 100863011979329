import styled from "styled-components";

export const AppContainer = styled("div")`
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: #3f4e4f;
  // font-family: "Poppins", sans-serif;
`;

export const SectionContainer = styled("div")`
  padding: 1.5em;
  margin: 0em 5em;
`;

export const SectionFullContainer = styled("div")`
  background-color: rgba(46, 171, 130, 0.1);
  padding: 12px;
`;

export const SectionTitle = styled("div")`
  display: flex;
  justify-content: center;
  color: #fff;
  margin-bottom: 5px;
  // font-family: Arial;
`;

export const ContentNewsLetter = styled("div")`
  display: flex;
  padding: 15px;
`;

export const ContentSideNewsLetter = styled("div")`
  display: flex;
  flex: 1;
  padding: 1px;
  margin: 5px;
  color: #000;
  justify-content: center;
  flex-direction: column;
`;

export const SubscribersCircle = styled("div")`
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
  // width: 11vw;
  // height: 11vw;
  padding: 20px;
  border-radius: 17vw;
  background-color: #258968;
  -webkit-box-shadow: 10px 10px 5px 5px rgba(224, 229, 227, 1);
  -moz-box-shadow: 10px 10px 5px 5px rgba(224, 229, 227, 1);
  box-shadow: 10px 10px 5px 5px rgba(224, 229, 227, 1);
  font-size: 16pt;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  border: 7px solid #ffcc00;
`;

export const ContainerProcurementType = styled("div")`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: px;
`;

export const SectionProcurementType = styled("div")`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  margin-left: 4px;
`;

export const SmallPaginationContainer = styled("div")`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
`;

export const RightSmallPaginationContainer = styled("div")`
  font-size: 12pt;
  display: flex;
  justify-content: flex-end;
  margin: 7px;
`;

export const SmallPaginationDirection = styled("div")`
  cursor: pointer;
  &:hover {
    color: #258968;
  }
`;

export const AssistanceContainer = styled("div")`
  padding: 5px;
  margin: 18px;
`;

export const FormationContainer = styled("div")`
  padding: 5px;
  margin: 18px;
`;

export const ContainerCardRecapAssistance = styled("div")`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin: 10px 25px;
  padding: 25px;
`;

export const ContainersNewsPage = styled("div")`
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
`;

export const ContainerPubs = styled("div")`
  margin: 10px;
`;

export const AuthorityInfosContainer = styled("div")`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
`;

export const SectionPPPContainer = styled("div")`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
`;

export const SectionFinanceContainer = styled("div")`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
`;

export const CustomTitle = styled("h1")`
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-size: 40px;
  color: #080808;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
`;

export const ProcurementDataContainer = styled("div")`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  align-items: start;
`;

export const ContainerFilterProcurement = styled("div")`
  background-color: #fff;
  border-radius: 5px;
  padding: 15px;
  -webkit-box-shadow: 10px 10px 5px 5px rgba(224, 229, 227, 1);
  -moz-box-shadow: 10px 10px 5px 5px rgba(224, 229, 227, 1);
  box-shadow: 10px 10px 5px 5px rgba(224, 229, 227, 1);
  margin-right: 10px;
`;

export const ContainerCardsProcurement = styled("div")`
  // background-color: #fff;
  border-radius: 5px;
  padding: 0px 15px 15px 15px;
  // -webkit-box-shadow: 10px 10px 5px 5px rgba(224, 229, 227, 1);
  // -moz-box-shadow: 10px 10px 5px 5px rgba(224, 229, 227, 1);
  // box-shadow: 10px 10px 5px 5px rgba(224, 229, 227, 1);
  margin-left: 10px;
`;

export const FilterItem = styled("div")`
  // margin: 10px;
  // padding: 10px;
`;

export const CustomFilterDateContainer = styled("div")`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
`;

export const ContainerFilterOption = styled("div")`
  padding: 5px;
  margin: 10px 0px;
  background-color: #ebfef5;
  border-radius: 5px;
`;

export const NewsLetterContent = styled("div")`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0fr 0fr 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  height: -webkit-fill-available;
`;

export const NewsLetterItem = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const LotContainer = styled("div")`
  padding: 5px;
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr 1fr 0.5fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  background-color: #dff1ff;
  font-size: 10pt;
`;

export const ContainersOpportunitiesPage = styled("div")`
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
`;

export const ModalMenuContainer = styled("div")`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0fr 4fr 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  width: 100%;
  height: 100%;
`;

export const ModalMenuLogoContainer = styled("div")`
  display: flex;
  justify-content: center;
  margin: 30px 0px;
  background-color: #FFCC00;
`;

export const ModalMenuItem = styled("div")`
  padding: 10px;
  margin: 10px 5px;
  color: #FFF;
`;
