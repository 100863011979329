import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  AppLogo,
  HeaderComponent,
  HeaderSideComponent,
  ItemMenu,
  ItemMenuAppName,
  ItemMenuOption,
  MenuMobileIcon,
  MenuMobileIconContainer,
} from "../styles/home";
import { FaSortDown } from "react-icons/fa";
import logo from "../assets/logo.svg";
import "../styles/dropdown.css";
import "../styles/animations.css";
import {
  EAtrributionType,
  WindowsSizeBreakpoints,
  isFieldWithValue,
  isMobileOrSmallScreen,
} from "../utils";
import HeaderContact from "./header-contact";
import { useWindowSize } from "../utils/useWindowSize";
import { TiThMenuOutline } from "react-icons/ti";
import ModalMenu from "../components/modals/modal-menu";

export default function HeaderApp({ isMobileDevice = false }) {
  // eslint-disable-next-line
  const [activeTab, setActiveTab] = useState(window.location.pathname || "");
  const [isConnected, setIsConnected] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [openMenu, setOpenMenu] = useState(false);
  const { width } = useWindowSize();

  useEffect(() => {
    try {
      const data = window.localStorage.getItem("account");
      const user = JSON.parse(data);
      if (
        !isFieldWithValue(user?.email) ||
        !isFieldWithValue(user?.access_token)
      ) {
        setIsConnected(false);
      } else {
        setIsConnected(true);
      }
    } catch (error) {
      console.log({ error });
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;

      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  return (
    <HeaderComponent className="background-image-tree" style={{
      backgroundColor: isMobileOrSmallScreen() ? "#2EAB82" : "#FFF"
    }}>
      {visible && !isMobileOrSmallScreen() && (
        <HeaderSideComponent
          // position="flex-end"
          style={{
            width: "100%",
            borderBottom: "1px solid #EBEBEB",
          }}
        >
          <HeaderContact />
        </HeaderSideComponent>
      )}
      {Boolean(width <= WindowsSizeBreakpoints.large) ? (
        <HeaderSideComponent position="flex-start" style={{ flex: 1, width: "-webkit-fill-available", }}>
          <div style={{ flex: 1,}}>
          <Link to="/">
            <ItemMenuAppName
              className="animate__animated animate__zoomIn"
              style={{ marginRight: "10vw" }}
            >
              <AppLogo src={logo} style={{
                height: isMobileOrSmallScreen() ? "10vw" : "3.7vw"
              }}/>
            </ItemMenuAppName>
          </Link>
          </div>
          <MenuMobileIconContainer>
            <MenuMobileIcon onClick={() => setOpenMenu(true)}>
              <TiThMenuOutline color="#2F4858"/>
            </MenuMobileIcon>
          </MenuMobileIconContainer>
        </HeaderSideComponent>
        // null
      ) : (
        <HeaderSideComponent position="center" style={{ flex: 1 }}>
          <Link to="/">
            <ItemMenuAppName
              className="animate__animated animate__zoomIn"
              style={{ marginRight: "10vw" }}
            >
              <AppLogo src={logo} style={{ height: "2.7vw"}} alt="logo-app" />
            </ItemMenuAppName>
          </Link>
          <Link to="/">
            <ItemMenu
              style={{
                ...(activeTab === "/" && {
                  borderBottom: "2px solid #258925",
                }),
              }}
            >
              ACCUEIL <FaSortDown color="#fff" />
            </ItemMenu>
          </Link>
          <Link to="/news">
            <ItemMenu
              style={{
                ...(activeTab.includes("/news") && {
                  borderBottom: "2px solid #258925",
                }),
              }}
            >
              ACTUALITÉS <FaSortDown color="#fff" />
            </ItemMenu>
          </Link>
          <div className="dropdown">
            <ItemMenu
              style={{
                ...((activeTab.includes("/plan-de-march") ||
                  activeTab.includes("/appels-d-offres") ||
                  activeTab.includes("/avis-d-attributions")) && {
                  borderBottom: "2px solid #258925",
                }),
              }}
            >
              LES MARCHÉS <FaSortDown style={{ verticalAlign: "text-top" }} />
            </ItemMenu>
            <div className="dropdown-content">
              <Link to="/plan-de-marchés">
                <small href="#">PLAN DES MARCHÉS</small>
              </Link>
              <Link to="/appels-d-offres">
                <small href="#">AVIS D'APPELS D'OFFRES</small>
              </Link>
              <Link
                to={`/avis-d-attributions`} ///${EAtrributionType.PROVISIONAL.toLocaleLowerCase()}
              >
                <small href="#">AVIS D'ATTRIBUTION DES MARCHÉS</small>
              </Link>
              {/* <Link
                to={`/avis-d-attributions/${EAtrributionType.DEFINITIVE.toLocaleLowerCase()}`}
              >
                <small href="#">AVIS D'ATTRIBUTION DÉFINITIVE</small>
              </Link> */}
            </div>
          </div>
          <Link to="/financement">
            <ItemMenu
              style={{
                ...(activeTab.includes("/financement") && {
                  borderBottom: "2px solid #258925",
                }),
              }}
            >
              FINANCEMENT <FaSortDown color="#fff" />
            </ItemMenu>
          </Link>
          <div className="dropdown">
            <ItemMenu
              style={{
                ...(["/assistance", "/formation", "/audit"].includes(
                  activeTab
                ) && {
                  borderBottom: "2px solid #258925",
                }),
              }}
            >
              NOS SERVICES <FaSortDown style={{ verticalAlign: "text-top" }} />{" "}
            </ItemMenu>
            <div className="dropdown-content">
              <Link to="/assistance">
                <small href="#">ASSISTANCE</small>
              </Link>
              <Link to="/formation">
                <small href="#">FORMATION</small>
              </Link>
              <Link to="/audit">
                <small>AUDIT</small>
              </Link>
            </div>
          </div>
          {/* <div className="dropdown">
          <ItemMenu
            style={{
              ...(activeTab.includes("/authority") && {
                borderBottom: "2px solid #258925",
              }),
            }}
          >
            ORGANES <FaSortDown style={{ verticalAlign: "text-top" }} />
          </ItemMenu>
          <div className="dropdown-content">
            <Link to="/authority/dcmp">
              <small href="#">DCMP</small>
            </Link>
            <Link to="/authority/arcop">
              <small href="#">ARCOP</small>
            </Link>
            <Link to="/authority/cdmp">
              <small href="#">CDMP</small>
            </Link>
          </div>
        </div> */}
          <Link to="/opportunities">
            <ItemMenu
              style={{
                ...(activeTab.includes("/opportunities") && {
                  borderBottom: "2px solid #258925",
                }),
              }}
            >
              OPPORTUNITÉS D'AFF. <FaSortDown color="#fff" size={0} />
            </ItemMenu>
          </Link>
          {isConnected ? (
            
              <Link to="/dashboard">
              <ItemMenuOption
                style={{
                  marginLeft: "13vw",
                  borderRadius: "55px",
                  backgroundColor: "#FFF",
                  color: "#2EAB82",
                  border: "1px solid #2EAB82",
                }}
              >
                Dashboard
              </ItemMenuOption>
            </Link>
          ) : (
            <Link to="/login">
              <ItemMenuOption
                style={{ marginLeft: "13vw", borderRadius: "55px" }}
              >
                Connexion
              </ItemMenuOption>
            </Link>
          )}
        </HeaderSideComponent>
      )}
      <ModalMenu
        isOpen={openMenu}
      />
    </HeaderComponent>
  );
}
