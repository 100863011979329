import React, { useEffect, useRef, useState } from "react";
import {
  ContainerCardsProcurement,
  ContainerFilterOption,
  ContainerFilterProcurement,
  ContainerPubs,
  CustomSelect,
  FilterItem,
  ProcurementDataContainer,
  SectionContainer,
} from "../styles/containers";
import CustomCollapse from "./extras/custom-collapse";
import {
  EProcurementType,
  WindowsSizeBreakpoints,
  formatDateFilter,
  formatDateToDDMMYYYY,
  getProcurementTypeTraduction,
  isFieldWithValue,
  isMobileOrSmallScreen,
} from "../utils";
import CustomFilterDate from "./extras/custom-filter-date";
import CustomFilterSearch from "./extras/custom-filter-search";
import CardGeneralNotice from "./cards/card-general-notice";
import { GetGeneralNotices, GetPubs } from "../configuration/api";
import CustomPagination from "./extras/pagination";
import { ItemMenuOption } from "../styles/home";
import { useDidMountEffect } from "../utils/useDidMountEffect";
import CardPub from "./cards/card-pub";
import ModalCard from "./modals/modal-card";
import HeaderCardDetails from "./details/header-card-details";
import FooterCardDetails from "./details/footer-card-details";
import GeneralNoticeDetails from "./details/general-notice-details";
import { useWindowSize } from "../utils/useWindowSize";

export default function SectionGeneralNotice({ recapData, defaultSearchTerm = "" }) {
  const refFilter = useRef(null);
  const [itemsCollapse, setItemsCollapse] = useState([
    {
      name: getProcurementTypeTraduction.TRAVAUX,
      count: recapData?.procurement_plans_travaux,
      value: EProcurementType.TRAVAUX,
    },
    {
      name: getProcurementTypeTraduction.FOURNITURES,
      count: recapData?.procurement_plans_fournitures,
      value: EProcurementType.FOURNITURES,
    },
    {
      name: getProcurementTypeTraduction.SERVICES_COURANTS,
      count: recapData?.procurement_plans_services,
      value: EProcurementType.SERVICES_COURANTS,
    },
    {
      name: getProcurementTypeTraduction.PRESTATIONS_INTELLECTUELLES,
      count: recapData?.procurement_plans_prestations,
      value: EProcurementType.PRESTATIONS_INTELLECTUELLES,
    },
  ]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(8);
  const [searchTerm, setSearchTerm] = useState(defaultSearchTerm || "");
  const [general_notices, setNewGeneralNotices] = useState(null);
  const [publicationDate, setPublicationDate] = useState({
    startDate: null,
    endDate: null,
  });
  const [limitDate, setLimitDate] = useState({
    startDate: null,
    endDate: null,
  });
  const [checkedTypes, setCheckedTypes] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [openModalData, setOpenModalData] = useState(false);
  const [pubs, setPubs] = useState([]);
  const [isMobileDevice, _] = useState(isMobileOrSmallScreen());
  const { width } = useWindowSize();

  useEffect(() => {
    getPubs();
  }, []);


  useEffect(() => {
    getGeneralNotices();
  }, [page]);

  useEffect(() => {
    if (general_notices?.length) {
      setIsLoadingData(false);
    }
  }, [general_notices]);

  useDidMountEffect(() => {
    reInitPagination();
  }, [searchTerm]);

  useDidMountEffect(() => {
    reInitPagination();
  }, [checkedTypes]);

  useEffect(() => {
    if (selectedItem) {
      setOpenModalData(true);
      document.body.classList.add("modal-open");
    } else {
      setOpenModalData(false);
      document.body.classList.remove("modal-open");
    }
  }, [selectedItem]);

  const getPubs = async () => {
    try {
      const payload = {
        page: 1,
        limit: 50,
      };
      const response = await GetPubs(payload);
      const { success, data, error } = response.data;
      if (success) {
        setPubs(data.pubs || []);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const getGeneralNotices = async () => {
    try {
      const payload = {
        page,
        limit,
        searchTerm,
        ...(isFieldWithValue(publicationDate.startDate) && {
          publicationStartDate: formatDateFilter(publicationDate.startDate),
        }),
        ...(isFieldWithValue(publicationDate.endDate) && {
          publicationEndDate: formatDateFilter(publicationDate.endDate),
        }),
        ...(isFieldWithValue(limitDate.startDate) && {
          limitStartDate: formatDateFilter(limitDate.startDate),
        }),
        ...(isFieldWithValue(limitDate.endDate) && {
          limitEndDate: formatDateFilter(limitDate.endDate),
        }),
        types: checkedTypes,
      };
      setIsLoadingData(true);
      const response = await GetGeneralNotices(payload);
      const { success, data, error } = response.data;
      if (success) {
        setTotal(data.total);
        setNewGeneralNotices(data.general_notices);
        if (!data.general_notices.length) {
          setIsLoadingData(false);
        }
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const previousPage = () => {
    if (page !== 1) {
      isMobileDevice
        ? refFilter?.current?.scrollIntoView()
        : window.scrollTo(0, 0);
      setPage(page - 1);
    }
  };

  const nextPage = () => {
    if (Math.ceil(total / limit) > page) {
      isMobileDevice
        ? refFilter?.current?.scrollIntoView()
        : window.scrollTo(0, 0);
      setPage(page + 1);
    }
  };

  const reInitPagination = () => {
    setIsLoadingData(true);
    isMobileDevice
      ? refFilter?.current?.scrollIntoView()
      : window.scrollTo(0, 0);
    if (page === 1) getGeneralNotices();
    else setPage(1);
  };

  const onPublicationDateFilterChange = (value) => {
    try {
      setPublicationDate(value);
    } catch (error) {}
  };

  const onLimitDateFilterChange = (value) => {
    try {
      setLimitDate(value);
    } catch (error) {}
  };

  const onFilterByPublicationDate = () => {
    try {
      if (isFieldWithValue(publicationDate.startDate)) {
        reInitPagination();
      }
    } catch (error) {}
  };

  const onFilterByLimitDate = () => {
    try {
      if (isFieldWithValue(limitDate.startDate)) {
        reInitPagination();
      }
    } catch (error) {}
  };

  const onSearchTermValueChange = (value = "") => {
    try {
      setSearchTerm(value);
    } catch (error) {
      console.log({ error });
    }
  };

  const onSelectType = (item) => {
    try {
      const values = checkedTypes || [];
      const index = values.findIndex((i) => i === item.value);
      if (index === -1) {
        values.push(item.value);
      } else {
        values.splice(index, 1);
      }
      setCheckedTypes([...values]);
    } catch (error) {}
  };

  const onSelectData = (data) => {
    try {
      setSelectedItem(data);
    } catch (error) {}
  };

  const formatFooterDetailsInfos = () => {
    try {
      const footerData = [
        {
          label: "DATE DE PUBLICATION",
          data: formatDateToDDMMYYYY(selectedItem?.publicationDate),
        },
        {
          label: selectedItem?.isDeferralNotice
            ? "DATE DE REPORT"
            : "DATE LIMITE",
          data: formatDateToDDMMYYYY(
            selectedItem?.limitDate || selectedItem?.referralDate
          ),
        },
      ];
      if (selectedItem?.isDeferralNotice) {
        footerData.push({
          label: "AVIS DE REPORT",
          data: "Ceci est un avis de report",
        });
      }
      if (selectedItem?.lots?.length) {
        footerData.push({
          label: "NOMBRE DE LOTS",
          data: selectedItem.lots.length,
        });
      }
      return footerData;
    } catch (error) {
      return [];
    }
  };

  return (
    <SectionContainer
      style={{
        backgroundColor: "#EFF5F3",
        margin: "0em",
        padding:
          isMobileDevice || Boolean(width <= WindowsSizeBreakpoints.medium)
            ? "0em 0vw 1.5em 0vw"
            : "0em 8vw 1.5em 8vw",
      }}
      id="topData"
    >
      <ProcurementDataContainer
        style={{
          ...(isMobileDevice || Boolean(width <= WindowsSizeBreakpoints.medium)
            ? {
                display: "contents",
              }
            : {
                display: "grid",
              }),
        }}
      >
        <div>
          <ContainerFilterProcurement
            style={{
              ...(isMobileDevice ||
              Boolean(width <= WindowsSizeBreakpoints.medium)
                ? {
                    backgroundColor: "#2EAB82",
                    margin: "10px 10px 25px 10px",
                    boxShadow: "none",
                  }
                : {
                    backgroundColor: "#2EAB82",
                  }),
            }}
          >
            <h3
              style={{
                marginTop: "0em",
                marginBottom: "0em",
                color: "#FFF", //1D1D35
                textAlign: "center",
              }}
              ref={refFilter}
            >
              FILTRES
            </h3>
            <FilterItem>
              <CustomFilterSearch onSearchTerm={onSearchTermValueChange} defaultValue={defaultSearchTerm}/>
              <div
                style={{ marginBottom: "20px", backgroundColor: "transparent" }}
              >
                <CustomCollapse
                  options={itemsCollapse}
                  title="Type de marchés"
                  checked={checkedTypes}
                  onClick={onSelectType}
                />
              </div>
              <ContainerFilterOption
                style={{ marginBottom: "20px", backgroundColor: "#F1F3F5" }}
              >
                <div style={{ textAlign: "center" }}>
                  <small
                    style={{
                      fontSize: "10pt",
                      color: "#000",
                      fontWeight: "bold",
                    }}
                  >
                    Date de publication
                  </small>
                </div>
                <CustomFilterDate
                  startDate={publicationDate.startDate}
                  endDate={publicationDate.endDate}
                  onDateChange={onPublicationDateFilterChange}
                />
                <ItemMenuOption
                  style={{
                    borderRadius: "5px",
                    backgroundColor: "#245B6D",
                    margin: "0px",
                    color: "#FFF",
                  }}
                  onClick={onFilterByPublicationDate}
                >
                  Filtrer
                </ItemMenuOption>
              </ContainerFilterOption>
              <ContainerFilterOption style={{ backgroundColor: "#F1F3F5" }}>
                <div style={{ textAlign: "center" }}>
                  <small
                    style={{
                      fontSize: "10pt",
                      color: "#000",
                      fontWeight: "bold",
                    }}
                  >
                    Date limite de dépôt
                  </small>
                </div>
                <CustomFilterDate
                  startDate={limitDate.startDate}
                  endDate={limitDate.endDate}
                  onDateChange={onLimitDateFilterChange}
                />
                <ItemMenuOption
                  style={{
                    borderRadius: "5px",
                    backgroundColor: "#245B6D",
                    margin: "0px",
                    color: "#FFF",
                  }}
                  onClick={onFilterByLimitDate}
                >
                  Filtrer
                </ItemMenuOption>
              </ContainerFilterOption>
            </FilterItem>
          </ContainerFilterProcurement>
          {!isMobileDevice && width > WindowsSizeBreakpoints.medium && (
            <ContainerPubs>
              <small className="pubs">Publicités</small>
              {pubs?.map((data, key) => (
                <CardPub urlImg={data.image} name={data.name} key={key} />
              ))}
            </ContainerPubs>
          )}
        </div>
        <ContainerCardsProcurement
          style={{
            ...(isMobileDevice ||
            Boolean(width <= WindowsSizeBreakpoints.medium)
              ? {
                  marginLeft: "0px",
                  marginTop: "15px",
                }
              : {
                  marginLeft: "10px",
                }),
          }}
        >
          {isLoadingData ? (
            <div
              className="in-container-loader"
              style={{ marginBottom: "100px" }}
            >
              <div className="spinner-loader"></div>
            </div>
          ) : (
            <>
              {general_notices?.map((data, key) => (
                <CardGeneralNotice
                  key={key}
                  data={data}
                  onClick={onSelectData}
                />
              ))}
              {Boolean(general_notices?.length) ? (
                <CustomPagination
                  page={page}
                  onNextPage={nextPage}
                  onPreviousPage={previousPage}
                  isLoadingData={isLoadingData}
                  limit={limit}
                  total={total}
                />
              ) : (
                <h5 style={{ textAlign: "center" }}>Aucun résultat !</h5>
              )}
            </>
          )}
        </ContainerCardsProcurement>
      </ProcurementDataContainer>
      <ModalCard
        isOpen={openModalData}
        onClose={() => setSelectedItem(null)}
        content={<GeneralNoticeDetails data={selectedItem} />}
        headerBackgroundColor="#2EAB82"
        header={
          <HeaderCardDetails
            data={selectedItem}
            headerGridTemplateColumns="1fr 3fr 1fr"
            primaryColor="#FFCC00"
            secondaryColor="#2EAB82"
            textColor="#000"
          />
        }
        footer={
          <FooterCardDetails
            data={formatFooterDetailsInfos()}
            footerGridTemplateColumns="repeat(3, 1fr)"
          />
        }
        marginTop="10vh"
      />
    </SectionContainer>
  );
}
