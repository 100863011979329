import React from "react";

export default function CustomCollapse({
  title = "",
  options = [],
  checked = [],
  onClick,
  color = "#FFCC00"
}) {
  return (
    <div className="card-collapse">
      <details className="option" style={{
        borderLeftColor: color,
        highlight: color
      }}>
        <summary>{title}</summary>
        {options?.map((item, key) => (
          <p
            key={key}
            onClick={() => onClick(item)}
            style={{
              ...(checked.includes(item.value) && {
                backgroundColor: "#FFCC00",
              }),
            }}
          >
            {item.name}
            {/* <b>({item.count})</b> */}
          </p>
        ))}
      </details>
    </div>
  );
}
