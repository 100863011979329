import React from "react";
import {
  BodyCardGeneralNoticeContainer,
  InLineContentDataCardGeneralNotice,
  LabelCardGeneralNotice,
  ValueCardGeneralNotice,
} from "../../styles/cards";
import { getProcurementTypeTraduction, isFieldWithValue } from "../../utils";

export default function GeneralNoticeDetails({ data }) {
  return (
    <BodyCardGeneralNoticeContainer
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: "10px",
      }}
    >
      <InLineContentDataCardGeneralNotice>
        <LabelCardGeneralNotice
          style={{ color: "#000", fontSize: "8pt", marginRight: "5px" }}
        >
          TYPE DE MARCHÉ:
        </LabelCardGeneralNotice>
        <ValueCardGeneralNotice style={{ color: "#000" }}>
          {getProcurementTypeTraduction[data?.type]}
        </ValueCardGeneralNotice>
      </InLineContentDataCardGeneralNotice>
      <InLineContentDataCardGeneralNotice>
        <LabelCardGeneralNotice
          style={{ color: "#000", fontSize: "8pt", marginRight: "5px" }}
        >
          MÉTHODE:
        </LabelCardGeneralNotice>
        <ValueCardGeneralNotice style={{ color: "#000" }}>
          {data?.method?.toUpperCase()}
        </ValueCardGeneralNotice>
      </InLineContentDataCardGeneralNotice>

      <InLineContentDataCardGeneralNotice
        style={{
          flexDirection: "column",
          alignItems: "unset",
          fontWeight: "bold",
        }}
      >
        <LabelCardGeneralNotice
          style={{ color: "#000", fontSize: "8pt", marginRight: "5px" }}
        >
          RÉALISATION: &nbsp; &nbsp;
        </LabelCardGeneralNotice>
        <ValueCardGeneralNotice style={{ color: "#000", fontWeight: "normal" }}>
          {data?.realization}
        </ValueCardGeneralNotice>
      </InLineContentDataCardGeneralNotice>

      <InLineContentDataCardGeneralNotice
        style={{
          flexDirection: "column",
          alignItems: "unset",
          fontWeight: "bold",
        }}
      >
        <LabelCardGeneralNotice
          style={{ color: "#000", fontSize: "8pt", marginRight: "5px" }}
        >
          DESCRIPTION: &nbsp; &nbsp;
        </LabelCardGeneralNotice>
        <ValueCardGeneralNotice style={{ color: "#000", fontWeight: "normal" }}>
          {data?.description}
        </ValueCardGeneralNotice>
      </InLineContentDataCardGeneralNotice>


      {Boolean(isFieldWithValue(data?.warrantySubmission)) && (
        <InLineContentDataCardGeneralNotice
          style={{ flexDirection: "column", alignItems: "unset" }}
        >
          <LabelCardGeneralNotice
            style={{
              color: "#000",
              fontSize: "8pt",
              marginRight: "5px",
              fontWeight: "bold",
            }}
          >
            GARANTIE DE SOUMISSION: &nbsp; &nbsp;
          </LabelCardGeneralNotice>
          <ValueCardGeneralNotice
            style={{ color: "#000", fontWeight: "normal" }}
          >
            {data?.warrantySubmission}
          </ValueCardGeneralNotice>
        </InLineContentDataCardGeneralNotice>
      )}
      {Boolean(isFieldWithValue(data?.warrantyGoodExecution)) && (
        <InLineContentDataCardGeneralNotice
          style={{ flexDirection: "column", alignItems: "unset" }}
        >
          <LabelCardGeneralNotice
            style={{
              color: "#000",
              fontSize: "8pt",
              marginRight: "5px",
              fontWeight: "bold",
            }}
          >
            GARANTIE DE BONNE EXÉCUTION: &nbsp; &nbsp;
          </LabelCardGeneralNotice>
          <ValueCardGeneralNotice
            style={{ color: "#000", fontWeight: "normal" }}
          >
            {data?.warrantyGoodExecution}
          </ValueCardGeneralNotice>
        </InLineContentDataCardGeneralNotice>
      )}
      {Boolean(isFieldWithValue(data?.warrantyDecennial)) && (
        <InLineContentDataCardGeneralNotice
          style={{ flexDirection: "column", alignItems: "unset" }}
        >
          <LabelCardGeneralNotice
            style={{
              color: "#000",
              fontSize: "8pt",
              marginRight: "5px",
              fontWeight: "bold",
            }}
          >
            GARANTIE DÉCENNALE: &nbsp; &nbsp;
          </LabelCardGeneralNotice>
          <ValueCardGeneralNotice
            style={{ color: "#000", fontWeight: "normal" }}
          >
            {data?.warrantyDecennial}
          </ValueCardGeneralNotice>
        </InLineContentDataCardGeneralNotice>
      )}
      {Boolean(isFieldWithValue(data?.financialCapacity)) && (
        <InLineContentDataCardGeneralNotice
          style={{ flexDirection: "column", alignItems: "unset" }}
        >
          <LabelCardGeneralNotice
            style={{
              color: "#000",
              fontSize: "8pt",
              marginRight: "5px",
              fontWeight: "bold",
            }}
          >
            CAPACITÉ FINANCIÉRE: &nbsp; &nbsp;
          </LabelCardGeneralNotice>
          <ValueCardGeneralNotice
            style={{ color: "#000", fontWeight: "normal" }}
          >
            {data?.financialCapacity}
          </ValueCardGeneralNotice>
        </InLineContentDataCardGeneralNotice>
      )}
      {Boolean(isFieldWithValue(data?.technicalCapacity)) && (
        <InLineContentDataCardGeneralNotice
          style={{ flexDirection: "column", alignItems: "unset" }}
        >
          <LabelCardGeneralNotice
            style={{
              color: "#000",
              fontSize: "8pt",
              marginRight: "5px",
              fontWeight: "bold",
            }}
          >
            CAPACITÉ TECHNIQUE: &nbsp; &nbsp;
          </LabelCardGeneralNotice>
          <ValueCardGeneralNotice
            style={{ color: "#000", fontWeight: "normal" }}
          >
            {data?.technicalCapacity}
          </ValueCardGeneralNotice>
        </InLineContentDataCardGeneralNotice>
      )}
      {Boolean(isFieldWithValue(data?.experience)) && (
        <InLineContentDataCardGeneralNotice
          style={{ flexDirection: "column", alignItems: "unset" }}
        >
          <LabelCardGeneralNotice
            style={{
              color: "#000",
              fontSize: "8pt",
              marginRight: "5px",
              fontWeight: "bold",
            }}
          >
            EXPERIENCE: &nbsp; &nbsp;
          </LabelCardGeneralNotice>
          <ValueCardGeneralNotice
            style={{ color: "#000", fontWeight: "normal" }}
          >
            {data?.experience}
          </ValueCardGeneralNotice>
        </InLineContentDataCardGeneralNotice>
      )}
      <InLineContentDataCardGeneralNotice
          style={{ flexDirection: "column", alignItems: "unset" }}
        >
          <LabelCardGeneralNotice
            style={{
              color: "#000",
              fontSize: "8pt",
              marginRight: "5px",
              fontWeight: "bold",
            }}
          >
            RÉFERENCE DE PUBLICATION: &nbsp; &nbsp;
          </LabelCardGeneralNotice>
          <ValueCardGeneralNotice
            style={{ color: "#000", fontWeight: "normal" }}
          >
            {data?.publicationRef}
          </ValueCardGeneralNotice>
        </InLineContentDataCardGeneralNotice>
        {Boolean(data?.lots?.length) &&
        data.lots.map((value, index) => (
          <InLineContentDataCardGeneralNotice
            style={{
              flexDirection: "column",
              alignItems: "unset",
              fontWeight: "bold",
              backgroundColor: "#EFF5F5",
              padding: "8px",
              borderRadius: "5px",
            }}
            key={index}
          >
            <LabelCardGeneralNotice
              style={{ color: "#000", fontSize: "8pt", marginRight: "5px", marginBottom: "10px" }}
            >
              LOT {index + 1} &nbsp; &nbsp;
            </LabelCardGeneralNotice>
            <ValueCardGeneralNotice
              style={{ color: "#000", fontWeight: "normal" }}
            >
                <InLineContentDataCardGeneralNotice>
                <LabelCardGeneralNotice
                  style={{ color: "#000", fontSize: "8pt", marginRight: "5px" }}
                >
                  TITRE DE L'OFFRE:
                </LabelCardGeneralNotice>
                <ValueCardGeneralNotice style={{ color: "#000" }}>
                  {value?.title}
                </ValueCardGeneralNotice>
              </InLineContentDataCardGeneralNotice>
              <InLineContentDataCardGeneralNotice>
                <LabelCardGeneralNotice
                  style={{ color: "#000", fontSize: "8pt", marginRight: "5px" }}
                >
                  DESCRIPTION:
                </LabelCardGeneralNotice>
                <ValueCardGeneralNotice style={{ color: "#000" }}>
                  {value?.description}
                </ValueCardGeneralNotice>
              </InLineContentDataCardGeneralNotice>
              <InLineContentDataCardGeneralNotice>
                <LabelCardGeneralNotice
                  style={{ color: "#000", fontSize: "8pt", marginRight: "5px" }}
                >
                  EXIGENCES FINANCIÉRES:
                </LabelCardGeneralNotice>
                <ValueCardGeneralNotice style={{ color: "#000" }}>
                  {value?.financialExigences}
                </ValueCardGeneralNotice>
              </InLineContentDataCardGeneralNotice>
            </ValueCardGeneralNotice>
          </InLineContentDataCardGeneralNotice>
        ))}
    </BodyCardGeneralNoticeContainer>
  );
}
