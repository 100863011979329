import React, { useEffect, useRef, useState } from "react";
import {
  ContainerCardsProcurement,
  ContainerFilterOption,
  ContainerFilterProcurement,
  ContainerPubs,
  FilterItem,
  ProcurementDataContainer,
  SectionContainer,
} from "../styles/containers";
import CustomCollapse from "./extras/custom-collapse";
import {
  EProcurementType,
  WindowsSizeBreakpoints,
  formatDateFilter,
  formatDateToDDMMYYYY,
  getProcurementTypeTraduction,
  isFieldWithValue,
  isMobileOrSmallScreen,
} from "../utils";
import CustomFilterDate from "./extras/custom-filter-date";
import CustomFilterSearch from "./extras/custom-filter-search";
import { GetProcurementsPlans, GetPubs } from "../configuration/api";
import CustomPagination from "./extras/pagination";
import { ItemMenuOption } from "../styles/home";
import { useDidMountEffect } from "../utils/useDidMountEffect";
import CardProcurement from "./cards/card-procurement";
import CardPub from "./cards/card-pub";
import ModalCard from "./modals/modal-card";
import ProcurementDetails from "./details/procurement-details";
import HeaderCardDetails from "./details/header-card-details";
import FooterCardDetails from "./details/footer-card-details";
import { useWindowSize } from "../utils/useWindowSize";

export default function SectionProcurementPage({
  recapData,
  defaultSelectedType = "",
}) {
  const refFilter = useRef(null);
  const [itemsCollapse, setItemsCollapse] = useState([
    {
      name: getProcurementTypeTraduction.TRAVAUX,
      count: recapData?.procurement_plans_travaux,
      value: EProcurementType.TRAVAUX,
    },
    {
      name: getProcurementTypeTraduction.FOURNITURES,
      count: recapData?.procurement_plans_fournitures,
      value: EProcurementType.FOURNITURES,
    },
    {
      name: getProcurementTypeTraduction.SERVICES_COURANTS,
      count: recapData?.procurement_plans_services,
      value: EProcurementType.SERVICES_COURANTS,
    },
    {
      name: getProcurementTypeTraduction.PRESTATIONS_INTELLECTUELLES,
      count: recapData?.procurement_plans_prestations,
      value: EProcurementType.PRESTATIONS_INTELLECTUELLES,
    },
  ]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(8);
  const [searchTerm, setSearchTerm] = useState("");
  const [procurements, setProcurements] = useState(null);
  const [launchDate, setLaunchDate] = useState({
    startDate: null,
    endDate: null,
  });
  const [grantDate, setGrantDate] = useState({
    startDate: null,
    endDate: null,
  });
  // const [checkedTypes, setCheckedTypes] = useState([]);
  const [checkedTypes, setCheckedTypes] = useState(
    defaultSelectedType?.length &&
      [
        EProcurementType.FOURNITURES,
        EProcurementType.TRAVAUX,
        EProcurementType.PRESTATIONS_INTELLECTUELLES,
        EProcurementType.SERVICES_COURANTS,
      ].includes(defaultSelectedType.toLocaleUpperCase())
      ? [defaultSelectedType.toUpperCase()]
      : []
  );
  const [selectedItem, setSelectedItem] = useState(null);
  const [openModalData, setOpenModalData] = useState(false);
  const [pubs, setPubs] = useState([]);
  const [isMobileDevice, _] = useState(isMobileOrSmallScreen());
  const { width } = useWindowSize();

  useEffect(() => {
    getPubs();
  }, []);

  useEffect(() => {
    getGeneralNotices();
  }, [page]);

  useEffect(() => {
    if (procurements?.length) {
      setIsLoadingData(false);
    }
  }, [procurements]);

  useDidMountEffect(() => {
    reInitPagination();
  }, [searchTerm]);

  useDidMountEffect(() => {
    reInitPagination();
  }, [checkedTypes]);

  useEffect(() => {
    if (selectedItem) {
      setOpenModalData(true);
      document.body.classList.add("modal-open");
    } else {
      setOpenModalData(false);
      document.body.classList.remove("modal-open");
    }
  }, [selectedItem]);

  const getPubs = async () => {
    try {
      const payload = {
        page: 1,
        limit: 50,
      };
      const response = await GetPubs(payload);
      const { success, data, error } = response.data;
      if (success) {
        setPubs(data.pubs || []);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const getGeneralNotices = async () => {
    try {
      const payload = {
        page,
        limit,
        searchTerm,
        ...(isFieldWithValue(launchDate.startDate) && {
          launchStartDate: formatDateFilter(launchDate.startDate),
        }),
        ...(isFieldWithValue(launchDate.endDate) && {
          launchEndDate: formatDateFilter(launchDate.endDate),
        }),
        ...(isFieldWithValue(grantDate.startDate) && {
          grantStartDate: formatDateFilter(grantDate.startDate),
        }),
        ...(isFieldWithValue(grantDate.endDate) && {
          grantEndDate: formatDateFilter(grantDate.endDate),
        }),
        types: checkedTypes,
      };
      setIsLoadingData(true);
      const response = await GetProcurementsPlans(payload);
      const { success, data, error } = response.data;
      if (success) {
        setTotal(data.total);
        setProcurements(data.procurements_plans);
        if (!data.procurements_plans.length) {
          setIsLoadingData(false);
        }
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const previousPage = () => {
    if (page !== 1) {
      isMobileDevice
        ? refFilter?.current?.scrollIntoView()
        : window.scrollTo(0, 0);
      setPage(page - 1);
    }
  };

  const nextPage = () => {
    if (Math.ceil(total / limit) > page) {
      isMobileDevice
        ? refFilter?.current?.scrollIntoView()
        : window.scrollTo(0, 0);
      setPage(page + 1);
    }
  };

  const reInitPagination = () => {
    setIsLoadingData(true);
    isMobileDevice
      ? refFilter?.current?.scrollIntoView()
      : window.scrollTo(0, 0);
    if (page === 1) getGeneralNotices();
    else setPage(1);
  };

  const onLaunchDateFilterChange = (value) => {
    try {
      setLaunchDate(value);
    } catch (error) {}
  };

  const onGrantDateFilterChange = (value) => {
    try {
      setGrantDate(value);
    } catch (error) {}
  };

  const onFilterByLaunchDate = () => {
    try {
      if (isFieldWithValue(launchDate.startDate)) {
        reInitPagination();
      }
    } catch (error) {}
  };

  const onFilterByGrantDate = () => {
    try {
      if (isFieldWithValue(grantDate.startDate)) {
        reInitPagination();
      }
    } catch (error) {}
  };

  const onSearchTermValueChange = (value = "") => {
    try {
      setSearchTerm(value);
    } catch (error) {
      console.log({ error });
    }
  };

  const onSelectType = (item) => {
    try {
      const values = checkedTypes || [];
      const index = values.findIndex((i) => i === item.value);
      if (index === -1) {
        values.push(item.value);
      } else {
        values.splice(index, 1);
      }
      setCheckedTypes([...values]);
    } catch (error) {}
  };

  const onSelectData = (data) => {
    try {
      setSelectedItem(data);
    } catch (error) {}
  };

  const formatFooterDetailsInfos = () => {
    try {
      return [
        {
          label: "DATE DE LANCEMENT",
          data: formatDateToDDMMYYYY(selectedItem?.launchDate),
        },
        {
          label: "DATE D'ATTRIBUTION",
          data: formatDateToDDMMYYYY(selectedItem?.grantDate),
        },
      ];
    } catch (error) {
      return [];
    }
  };

  return (
    <SectionContainer
      style={{
        backgroundColor: "#EFF5F3",
        margin: "0em",
        padding:
          isMobileDevice || Boolean(width <= WindowsSizeBreakpoints.medium)
            ? "0em 0vw 1.5em 0vw"
            : "0em 8vw 1.5em 8vw",
      }}
      id="topData"
    >
      <ProcurementDataContainer
        style={{
          ...(isMobileDevice || Boolean(width <= WindowsSizeBreakpoints.medium)
            ? {
                display: "contents",
              }
            : {
                display: "grid",
              }),
        }}
      >
        <div>
          <ContainerFilterProcurement
            style={{
              ...(isMobileDevice ||
              Boolean(width <= WindowsSizeBreakpoints.medium)
                ? {
                    backgroundColor: "#D8E7E8",
                    margin: "10px 10px 25px 10px",
                    boxShadow: "none",
                  }
                : {
                    backgroundColor: "#FFF",
                  }),
            }}
          >
            <h3
              style={{
                marginTop: "0em",
                marginBottom: "0em",
                color: "#000",
                textAlign: "center",
              }}
              ref={refFilter}
            >
              FILTRES
            </h3>
            <FilterItem>
              <CustomFilterSearch
                onSearchTerm={onSearchTermValueChange}
                placeholder="#Mot clé (autorité, ref, mode de pass.)"
              />
              <div
                style={{ marginBottom: "20px", backgroundColor: "transparent" }}
              >
                <CustomCollapse
                  options={itemsCollapse}
                  title="Type de marchés"
                  checked={checkedTypes}
                  onClick={onSelectType}
                />
              </div>
              <ContainerFilterOption
                style={{ marginBottom: "20px", backgroundColor: "#F1F3F5" }}
              >
                <div style={{ textAlign: "center" }}>
                  <small
                    style={{
                      fontSize: "10pt",
                      color: "#000",
                      fontWeight: "bold",
                    }}
                  >
                    Date de lancement
                  </small>
                </div>
                <CustomFilterDate
                  startDate={launchDate.startDate}
                  endDate={launchDate.endDate}
                  onDateChange={onLaunchDateFilterChange}
                />
                <ItemMenuOption
                  style={{
                    borderRadius: "5px",
                    backgroundColor: "#245B6D",
                    margin: "0px",
                    color: "#FFF",
                  }}
                  onClick={onFilterByLaunchDate}
                >
                  Filtrer
                </ItemMenuOption>
              </ContainerFilterOption>
              <ContainerFilterOption style={{ backgroundColor: "#F1F3F5" }}>
                <div style={{ textAlign: "center" }}>
                  <small
                    style={{
                      fontSize: "10pt",
                      color: "#000",
                      fontWeight: "bold",
                    }}
                  >
                    Date d'attribution
                  </small>
                </div>
                <CustomFilterDate
                  startDate={grantDate.startDate}
                  endDate={grantDate.endDate}
                  onDateChange={onGrantDateFilterChange}
                />
                <ItemMenuOption
                  style={{
                    borderRadius: "5px",
                    backgroundColor: "#245B6D",
                    margin: "0px",
                    color: "#FFF",
                  }}
                  onClick={onFilterByGrantDate}
                >
                  Filtrer
                </ItemMenuOption>
              </ContainerFilterOption>
            </FilterItem>
          </ContainerFilterProcurement>
          {!isMobileDevice && width > WindowsSizeBreakpoints.medium && (
            <ContainerPubs>
              <small className="pubs">Publicités</small>
              {pubs?.map((data, key) => (
                <CardPub urlImg={data.image} name={data.name} key={key} />
              ))}
            </ContainerPubs>
          )}
        </div>

        <ContainerCardsProcurement
          style={{
            ...(isMobileDevice ||
            Boolean(width <= WindowsSizeBreakpoints.medium)
              ? {
                  marginLeft: "0px",
                  marginTop: "15px",
                }
              : {
                  marginLeft: "10px",
                }),
          }}
        >
          {isLoadingData ? (
            <div
              className="in-container-loader"
              style={{ marginBottom: "100px" }}
            >
              <div className="spinner-loader"></div>
            </div>
          ) : (
            <>
              {procurements?.map((data, key) => (
                <CardProcurement key={key} data={data} onClick={onSelectData} />
              ))}
              {Boolean(procurements?.length) ? (
                <CustomPagination
                  page={page}
                  onNextPage={nextPage}
                  onPreviousPage={previousPage}
                  isLoadingData={isLoadingData}
                  limit={limit}
                  total={total}
                  backgroundColor="#245B6D"
                />
              ) : (
                <h5 style={{ textAlign: "center" }}>Aucun résultat !</h5>
              )}
            </>
          )}
        </ContainerCardsProcurement>
      </ProcurementDataContainer>
      <ModalCard
        isOpen={openModalData}
        onClose={() => setSelectedItem(null)}
        content={<ProcurementDetails data={selectedItem} />}
        headerBackgroundColor="#468FA7"
        header={
          <HeaderCardDetails
            data={selectedItem}
            headerGridTemplateColumns="1fr 4fr"
            primaryColor="#245B6D"
            secondaryColor="#468FA7"
          />
        }
        footer={
          <FooterCardDetails
            data={formatFooterDetailsInfos()}
            footerGridTemplateColumns="repeat(3, 1fr)"
          />
        }
        marginTop="20vh"
      />
    </SectionContainer>
  );
}
