import React, { useState } from "react";
import { TopContent } from "../../styles/home";
import { CustomTitle, SectionTitle } from "../../styles/containers";
import { WindowsSizeBreakpoints, isMobileOrSmallScreen } from "../../utils";
import { useWindowSize } from "../../utils/useWindowSize";

export default function CustomHeaderTitle({ title = "" }) {
  const [isMobileDevice, _] = useState(isMobileOrSmallScreen());
  const { width } = useWindowSize();
  return (
    <TopContent
      style={{
        minHeight: "14vh",
        backgroundColor: "#EFF5F3",
        ...(isMobileDevice || Boolean(width <= WindowsSizeBreakpoints.medium)
          ? {
              marginTop: "6vh",
            }
          : {
              marginTop: "12vh",
            }),
      }}
    >
      <SectionTitle
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: "25px",
          width: "100%",
        }}
      >
        {/* <hr width="100%" size="2" color="#EBEBEB" />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            // alignItems: "center",
          }}
        >
          <h3
            style={{
              marginTop: "2em",
              marginBottom: "0.2em",
              color: "#000",
            //   textAlign: "center",
            }}
          >
            {title}
          </h3>
        </div>
        <hr width="100%" size="1" color="#2EAB82" /> */}
        <div className="three">
          <CustomTitle>{title}</CustomTitle>
        </div>
      </SectionTitle>
    </TopContent>
  );
}
