import React, { Component } from "react";
import OpportunityContext from "./Opportunity";

export default class OpportunityProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newOpportunity: null,
    };
  }

  render() {
    return (
      <OpportunityContext.Provider
        value={{
          newOpportunity: this.state.newOpportunity,
          setNewOpportunity: (newOpportunity) => {
            this.setState({
              newOpportunity,
            });
          },
        }}
      >
        {this.props.children}
      </OpportunityContext.Provider>
    );
  }
}
