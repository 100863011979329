import React, { useCallback, useState } from "react";
import {
  BodyCardGeneralNoticeContainer,
  CardGeneralNoticeContainer,
  ContentDataCardGeneralNotice,
  Flash,
  FooterCardGeneralNoticeContainer,
  HeaderCardGeneralNoticeContainer,
  InLineContentDataCardGeneralNotice,
  LabelCardGeneralNotice,
  ValueCardGeneralNotice,
} from "../../styles/cards";
import {
  formatDateToDDMMYYYY,
  getProcurementTypeTraduction,
  getRemainingDays,
  truncateText,
} from "../../utils";

export default function CardGeneralNotice({ data, onClick }) {
  const [remainingDays, setRemainingDays] = useState(
    getRemainingDays(data.isDeferralNotice ? data.referralDate : data.limitDate)
  );

  const getFlashColor = useCallback(() => {
    if (remainingDays < 0) return "#FF1F00";
    if (remainingDays > 5) return "#009888";
    if (remainingDays <= 5) return "#F07349";
  }, [remainingDays]);

  const displayRemainingDay = useCallback(() => {
    if (remainingDays === null) return <div></div>;
    if (remainingDays >= 0) {
      return (
        <Flash
          color={getFlashColor()}
          style={{ borderRadius: "0px", animationName: "flash-card", flexDirection: "column", color: "#FFF" }}
        >
          {data.isDeferralNotice ? "Fin report" : "Dépôt offres"}
          <b>
            {remainingDays} Jr{remainingDays > 1 ? "s" : ""} restant{remainingDays > 1 ? "s" : ""}
          </b>
          {""}
          {/* &nbsp;restant{remainingDays > 1 ? "s" : ""} */}
        </Flash>
      );
    }
    return (
      <Flash
        color={getFlashColor()}
        style={{ animationName: "none", color: "white", borderRadius: "0px" }}
      >
        <b>Délai dépassé</b> &nbsp;
      </Flash>
    );
  }, [remainingDays]);

  return (
    <CardGeneralNoticeContainer style={{ gridTemplateRows: "0fr" }} onClick={() => onClick(data)}>
      <HeaderCardGeneralNoticeContainer>
        <ContentDataCardGeneralNotice
          style={{
            backgroundColor: remainingDays < 0 ? "#FF1F00" : "#FFCC00",
          }}
        >
          <LabelCardGeneralNotice
            style={{
              color: remainingDays < 0 ? "#FFFF" : "#000",
              fontSize: "7pt",
            }}
          >
            RÉFÉRENCE
          </LabelCardGeneralNotice>
          <ValueCardGeneralNotice
            style={{
              fontSize: "8pt",
              color: remainingDays < 0 ? "#FFFF" : "#000",
            }}
          >
            {truncateText(data.ref, 10)}
          </ValueCardGeneralNotice>
        </ContentDataCardGeneralNotice>
        <ContentDataCardGeneralNotice
          style={{
            backgroundColor: remainingDays < 0 ? "#FFBAB1" : "#2EAB82",
          }}
        >
          <LabelCardGeneralNotice
            style={{ color: remainingDays < 0 ? "#000" : "#FFF" }}
          >
            AUTORITÉ CONTRACTANTE
          </LabelCardGeneralNotice>
          <ValueCardGeneralNotice
            style={{ color: remainingDays < 0 ? "#000" : "#FFF" }}
          >
            {truncateText(data.authority, 65)}
          </ValueCardGeneralNotice>
        </ContentDataCardGeneralNotice>
        {displayRemainingDay()}
      </HeaderCardGeneralNoticeContainer>
      <BodyCardGeneralNoticeContainer
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          //   alignItems: "center",
          borderBottom: "1px solid #E4EBE8",
          margin: "10px",
        }}
      >
        <InLineContentDataCardGeneralNotice>
          <LabelCardGeneralNotice
            style={{ color: "#000", fontSize: "8pt", marginRight: "5px" }}
          >
            TYPE DE MARCHÉ:
          </LabelCardGeneralNotice>
          <ValueCardGeneralNotice style={{ color: "#000" }}>
            {getProcurementTypeTraduction[data.type]}
          </ValueCardGeneralNotice>
        </InLineContentDataCardGeneralNotice>
        <InLineContentDataCardGeneralNotice
          style={{ flexDirection: "column", alignItems: "unset" }}
        >
          <LabelCardGeneralNotice
            style={{ color: "#000", fontSize: "8pt", marginRight: "5px" }}
          >
            RÉALISATION: &nbsp; &nbsp;
          </LabelCardGeneralNotice>
          <ValueCardGeneralNotice style={{ color: "#000" }}>
            {data.realization}
          </ValueCardGeneralNotice>
        </InLineContentDataCardGeneralNotice>
      </BodyCardGeneralNoticeContainer>
      <FooterCardGeneralNoticeContainer>
        <ContentDataCardGeneralNotice>
          <LabelCardGeneralNotice style={{ color: "#000", fontSize: "8pt" }}>
            PUBLIÉ LE
          </LabelCardGeneralNotice>
          <ValueCardGeneralNotice style={{ color: "#000" }}>
            {formatDateToDDMMYYYY(data.publicationDate)}
          </ValueCardGeneralNotice>
        </ContentDataCardGeneralNotice>
        <ContentDataCardGeneralNotice>
          <LabelCardGeneralNotice style={{ color: "#000", fontSize: "8pt" }}>
            {data.isDeferralNotice ? "DATE REPORT": "DATE LIMITE"}
          </LabelCardGeneralNotice>
          <ValueCardGeneralNotice style={{ color: "#000" }}>
            {formatDateToDDMMYYYY(data.limitDate || data.referralDate)}
          </ValueCardGeneralNotice>
        </ContentDataCardGeneralNotice>
        <ContentDataCardGeneralNotice>
          <LabelCardGeneralNotice style={{ color: "#000", fontSize: "8pt" }}>
            RÉFÉRENCE DE PUBLICATION
          </LabelCardGeneralNotice>
          <ValueCardGeneralNotice style={{ color: "#000", fontSize: "9pt" }}>
            {truncateText(data.publicationRef)}
          </ValueCardGeneralNotice>
        </ContentDataCardGeneralNotice>
      </FooterCardGeneralNoticeContainer>
    </CardGeneralNoticeContainer>
  );
}
