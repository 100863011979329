import React, { useState } from "react";
import {
  ContentDataCardGeneralNotice,
  FooterCardGeneralNoticeContainer,
  LabelCardGeneralNotice,
  ValueCardGeneralNotice,
} from "../../styles/cards";
import { WindowsSizeBreakpoints, formatDateToDDMMYYYY, isMobileOrSmallScreen } from "../../utils";
import { useWindowSize } from "../../utils/useWindowSize";

export default function FooterCardDetails({
  data = [],
  footerGridTemplateColumns = "",
}) {
  const [isMobileDevice, _] = useState(isMobileOrSmallScreen());
  const { width } = useWindowSize();
  return (
    <FooterCardGeneralNoticeContainer
      style={{
        gridTemplateColumns: footerGridTemplateColumns,
        width: "-webkit-fill-available",
        // display:
        //   isMobileDevice || Boolean(width <= WindowsSizeBreakpoints.medium)
        //     ? "block"
        //     : "grid",
      }}
    >
      {data?.map((item, key) => (
        <ContentDataCardGeneralNotice key={key}>
          <LabelCardGeneralNotice style={{ color: "#000", fontSize: "8pt" }}>
            {item.label}
          </LabelCardGeneralNotice>
          <ValueCardGeneralNotice style={{ color: "#000" }}>
            {item.data}
          </ValueCardGeneralNotice>
        </ContentDataCardGeneralNotice>
      ))}
    </FooterCardGeneralNoticeContainer>
  );
}
