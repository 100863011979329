import React from "react";
import { BodyCardGeneralNoticeContainer, InLineContentDataCardGeneralNotice, LabelCardGeneralNotice, ValueCardGeneralNotice } from "../../styles/cards";
import { getProcurementTypeTraduction } from "../../utils";

export default function ProcurementDetails({ data }) {
  return (
    <BodyCardGeneralNoticeContainer
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        //   alignItems: "center",
        margin: "10px",
      }}
    >
      <InLineContentDataCardGeneralNotice style={{
        flexDirection: "column",
        alignItems: "flex-start"
      }}>
        <LabelCardGeneralNotice style={{ color: "#000", fontSize: "8pt" }}>
          TYPE DE MARCHÉ: &nbsp; &nbsp;
        </LabelCardGeneralNotice>
        <ValueCardGeneralNotice style={{ color: "#000", fontWeight: "bold" }}>
          {getProcurementTypeTraduction[data?.type]}
        </ValueCardGeneralNotice>
      </InLineContentDataCardGeneralNotice>
      <InLineContentDataCardGeneralNotice style={{
        flexDirection: "column",
        alignItems: "flex-start"
      }}>
        <LabelCardGeneralNotice style={{ color: "#000", fontSize: "8pt" }}>
          MODE DE PASSATION: &nbsp; &nbsp;
        </LabelCardGeneralNotice>
        <ValueCardGeneralNotice style={{ color: "#000", fontWeight: "bold" }}>
          {data?.method}
        </ValueCardGeneralNotice>
      </InLineContentDataCardGeneralNotice>
      <InLineContentDataCardGeneralNotice style={{
        flexDirection: "column",
        alignItems: "flex-start"
      }}>
        <LabelCardGeneralNotice style={{ color: "#000", fontSize: "8pt" }}>
          RÉALISATION: &nbsp; &nbsp;
        </LabelCardGeneralNotice>
        <ValueCardGeneralNotice style={{ color: "#000" }}>
          {data?.realization}
        </ValueCardGeneralNotice>
      </InLineContentDataCardGeneralNotice>
    </BodyCardGeneralNoticeContainer>
  );
}
