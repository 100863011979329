import React, { useEffect, useState } from "react";
import {
  AppContainer,
  ContainerPubs,
  ContainersOpportunitiesPage,
  SectionTitle,
} from "../../styles/containers";
import HeaderApp from "../../containers/header";
import { BodyComponent, TopContent } from "../../styles/home";
import FooterApp from "../../containers/footer";
import SectionPartners from "../../components/section-partners";
import SectionNewsLetter from "../../components/section-newsletter";
import { WindowsSizeBreakpoints, isMobileOrSmallScreen } from "../../utils";
import CardPub from "../../components/cards/card-pub";
import { GetOpportunities, GetPubs } from "../../configuration/api";
import { CardContainer } from "../../styles/cards";
import CardOpportunity from "../../components/cards/card-opportunity";
import CustomPagination from "../../components/extras/pagination";
import { useWindowSize } from "../../utils/useWindowSize";

export default function Opportunities() {
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(8);
  const [opportunities, setOpportunities] = useState(null);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [pubs, setPubs] = useState([]);
  const [isMobileDevice, _] = useState(isMobileOrSmallScreen());
  const { width } = useWindowSize();

  useEffect(() => {
    getPubs();
  }, []);

  useEffect(() => {
    getOpportunities();
  }, [page]);

  useEffect(() => {
    if (opportunities) {
      setIsLoadingData(false);
    }
  }, [opportunities]);

  const getPubs = async () => {
    try {
      const payload = {
        page: 1,
        limit: 50,
      };
      const response = await GetPubs(payload);
      const { success, data, error } = response.data;
      if (success) {
        setPubs(data.pubs || []);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const getOpportunities = async () => {
    try {
      const payload = {
        page,
        limit,
      };
      setIsLoadingData(true);
      const response = await GetOpportunities(payload);
      const { success, data, error } = response.data;
      if (success) {
        setTotal(data.total);
        setOpportunities(data.opportunities);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const onNextPage = () => {
    window.scrollTo(0, 0);
    const maxPage = Math.ceil(total / limit);
    if (page < maxPage) {
      setPage(page + 1);
    }
  };

  const onPreviousPage = () => {
    window.scrollTo(0, 0);
    if (page > 1) {
      setPage(page - 1);
    }
  };

  return (
    <AppContainer>
      <HeaderApp />
      <BodyComponent>
        <TopContent
          style={{
            minHeight: "14vh",
            backgroundColor: "#FFF",
          }}
        >
          <SectionTitle
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: "25px",
              width: "100%",
            }}
          >
            <hr width="100%" size="2" color="#EBEBEB" />
            <h5
              style={{
                marginTop: "0.5em",
                marginBottom: "0em",
                color: "#000",
                textAlign: "center",
              }}
            >
              OPPORTUNITÉS D'AFFAIRES
            </h5>
            <hr width="100%" size="2" color="#EBEBEB" />
          </SectionTitle>
        </TopContent>
        <ContainersOpportunitiesPage
          style={{
            ...(isMobileDevice || Boolean(width <= WindowsSizeBreakpoints.medium)
            ? {
                display: "contents",
              }
            : {
                display: "grid",
              }),
          }}
        >
          {!isMobileDevice && width > WindowsSizeBreakpoints.medium && (
            <ContainerPubs>
              <small className="pubs">Publicités</small>
              {pubs?.map((data, key) => (
                <CardPub urlImg={data.image} name={data.name} key={key} />
              ))}
            </ContainerPubs>
          )}
          <div
            style={{
              marginRight: "5vw",
            }}
          >
            <CardContainer
              style={{
                justifyContent: isLoadingData ? "center" : "flex-start",
                marginTop: "0px",
                borderLeft: "1px solid #EBEBEB",
                flexDirection: "column",
              }}
              className="container"
            >
              {isLoadingData ? (
                <div className="in-container-loader">
                  <div className="spinner-loader"></div>
                </div>
              ) : (
                opportunities?.map((value, key) => (
                    <CardOpportunity data={value} key={value.code} />
                ))
              )}
            </CardContainer>
            {Boolean(!isLoadingData) && (
              <>
              {Boolean(opportunities?.length) ? (
                <CustomPagination
                  page={page}
                  onNextPage={onNextPage}
                  onPreviousPage={onPreviousPage}
                  isLoadingData={isLoadingData}
                  limit={limit}
                  total={total}
                  backgroundColor="#374B42"
                />
              ) : (
                <h5 style={{ textAlign: "center" }}>Aucun résultat !</h5>
              )}
              </>
            )}
          </div>
        </ContainersOpportunitiesPage>
        <SectionPartners />
        <SectionNewsLetter />
      </BodyComponent>
      <FooterApp />
    </AppContainer>
  );
}
