import React, { useCallback, useState } from "react";
import {
  BodyCardGeneralNoticeContainer,
  CardGeneralNoticeContainer,
  ContentDataCardGeneralNotice,
  Flash,
  FooterCardGeneralNoticeContainer,
  HeaderCardGeneralNoticeContainer,
  InLineContentDataCardGeneralNotice,
  LabelCardGeneralNotice,
  ValueCardGeneralNotice,
} from "../../styles/cards";
import { formatDateToDDMMYYYY, getProcurementTypeTraduction, truncateText } from "../../utils";

export default function CardProcurement({ data, onClick }) {
  return (
    <CardGeneralNoticeContainer onClick={() => onClick(data)}>
      <HeaderCardGeneralNoticeContainer style={{
        gridTemplateColumns: "1fr 4fr"
      }}>
        <ContentDataCardGeneralNotice
          style={{
            backgroundColor: "#245B6D",
          }}
        >
          <LabelCardGeneralNotice
            style={{
              color: "#FFF",
              fontSize: "7pt",
            }}
          >
            RÉFÉRENCE
          </LabelCardGeneralNotice>
          <ValueCardGeneralNotice
            style={{
              fontSize: "8pt",
              color: "#FFF",
            }}
          >
            {data.ref}
          </ValueCardGeneralNotice>
        </ContentDataCardGeneralNotice>
        <ContentDataCardGeneralNotice
          style={{
            backgroundColor: "#468FA7",
          }}
        >
          <LabelCardGeneralNotice
            style={{ color: "#FFF" }}
          >
            AUTORITÉ CONTRACTANTE
          </LabelCardGeneralNotice>
          <ValueCardGeneralNotice
            style={{ color: "#FFF" }}
          >
            {truncateText(
              data.authority,
              65
            )}
          </ValueCardGeneralNotice>
        </ContentDataCardGeneralNotice>
      </HeaderCardGeneralNoticeContainer>
      <BodyCardGeneralNoticeContainer
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          borderBottom: "1px solid #E4EBE8",
          margin: "10px",
        }}
      >
        <InLineContentDataCardGeneralNotice>
          <LabelCardGeneralNotice style={{ color: "#000", fontSize: "8pt" }}>
            TYPE DE MARCHÉ: &nbsp; &nbsp;
          </LabelCardGeneralNotice>
          <ValueCardGeneralNotice style={{ color: "#000", fontWeight: "normal" }}>
            {getProcurementTypeTraduction[data.type]}
          </ValueCardGeneralNotice>
        </InLineContentDataCardGeneralNotice>
        <InLineContentDataCardGeneralNotice>
          <LabelCardGeneralNotice style={{ color: "#000", fontSize: "8pt" }}>
            MODE DE PASSATION: &nbsp; &nbsp;
          </LabelCardGeneralNotice>
          <ValueCardGeneralNotice style={{ color: "#000", fontWeight: "normal" }}>
            {data.method}
          </ValueCardGeneralNotice>
        </InLineContentDataCardGeneralNotice>
        <InLineContentDataCardGeneralNotice>
          <LabelCardGeneralNotice style={{ color: "#000", fontSize: "8pt" }}>
            RÉALISATION: &nbsp; &nbsp;
          </LabelCardGeneralNotice>
          <ValueCardGeneralNotice style={{ color: "#000" }}>
           {data.realization}
          </ValueCardGeneralNotice>
        </InLineContentDataCardGeneralNotice>
      </BodyCardGeneralNoticeContainer>
      <FooterCardGeneralNoticeContainer>
        <ContentDataCardGeneralNotice>
          <LabelCardGeneralNotice style={{ color: "#000", fontSize: "8pt" }}>
            DATE DE LANCEMENT
          </LabelCardGeneralNotice>
          <ValueCardGeneralNotice style={{ color: "#000" }}>
            {formatDateToDDMMYYYY(data.launchDate)}
          </ValueCardGeneralNotice>
        </ContentDataCardGeneralNotice>
        <ContentDataCardGeneralNotice>
          <LabelCardGeneralNotice style={{ color: "#000", fontSize: "8pt" }}>
            DATE D'ATTRIBUTION
          </LabelCardGeneralNotice>
          <ValueCardGeneralNotice style={{ color: "#000" }}>
          {formatDateToDDMMYYYY(data.grantDate)}
          </ValueCardGeneralNotice>
        </ContentDataCardGeneralNotice>
        
      </FooterCardGeneralNoticeContainer>
    </CardGeneralNoticeContainer>
  );
}
