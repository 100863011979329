import React, { useEffect, useState } from "react";
import { SectionFullContainer } from "../styles/containers";
import "../styles/partners.css";
import dcmp from "../assets/logo_dcmp.jpg";
import arcop from "../assets/arcop.jpg";
import cdmp from "../assets/cdmp.svg";
import ue from "../assets/ue.jpg";
import bm from "../assets/bm.jpg";
import usaid from "../assets/usaid.png";
import giz from "../assets/giz.png";
import canada from "../assets/canada.webp";
import bad from "../assets/bad.jpg";
import ocde from "../assets/ocde.png";
import { GetPartners } from "../configuration/api";

export default function SectionPartners({
  padding = "12px",
  margin = "auto",
  width = "95%",
}) {
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [partners, setPartners] = useState(null);

  useEffect(() => {
    getPartners();
  }, []);

  useEffect(() => {
    if (partners?.length) {
      setIsLoadingData(false)
    }
  }, [partners]);

  const getPartners = async () => {
    try {
      const payload = {
        page: 1,
        limit: 50,
      };
      setIsLoadingData(true);
      const response = await GetPartners(payload);
      const { success, data, error } = response.data;
      if (success) {
        setPartners(data.partners.reverse());
        if (data.total === 0) setIsLoadingData(false);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const openPartnerWebsite = (url) => {
    try {
      window.open(url, "_blank");
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <SectionFullContainer style={{ backgroundColor: "#fff", padding }}>
      {isLoadingData ? (
        <div className="in-container-loader">
        <div className="spinner-loader"></div>
      </div>
      ) : (
        <div className="slider" style={{ margin, width }}>
          <div className="slide-track">
            {partners?.reverse()?.map((partner, index) => (
              <div
              key={partner.code}
              className="slide"
              onClick={() =>
                openPartnerWebsite(
                  partner.url
                )
              }
            >
              <img
                src={partner.image}
                style={{
                  height: "90%",
                  width: "-webkit-fill-available",
                }}
                alt={partner.name}
              />
            </div>
            ))}
            {/* <div
              className="slide"
              onClick={() => openPartnerWebsite("https://arcop.sn/")}
            >
              <img
                src={arcop}
                style={{
                  height: "-webkit-fill-available",
                  width: "-webkit-fill-available",
                }}
                alt=""
              />
            </div>
            <div
              className="slide"
              onClick={() => openPartnerWebsite("https://cdmp.sn/")}
            >
              <img
                src={cdmp}
                // height="100"
                width="250"
                style={{
                  height: "-webkit-fill-available",
                  width: "-webkit-fill-available",
                }}
                alt=""
              />
            </div>
            <div
              className="slide"
              onClick={() =>
                openPartnerWebsite("https://european-union.europa.eu/index_fr")
              }
            >
              <img
                src={ue}
                style={{ height: "90%", width: "-webkit-fill-available" }}
                alt=""
              />
            </div>
            <div
              className="slide"
              style={{ width: "auto" }}
              onClick={() =>
                openPartnerWebsite("https://www.banquemondiale.org/fr/home")
              }
            >
              <img
                src={bm}
                style={{
                  height: "-webkit-fill-available",
                  width: "-webkit-fill-available",
                }}
                alt=""
              />
            </div>
            <div
              className="slide"
              style={{ width: "auto" }}
              onClick={() => openPartnerWebsite("https://www.usaid.gov/")}
            >
              <img
                src={usaid}
                style={{
                  height: "-webkit-fill-available",
                  width: "-webkit-fill-available",
                }}
                alt=""
              />
            </div>
            <div
              className="slide"
              onClick={() =>
                openPartnerWebsite("https://www.giz.de/en/html/index.html")
              }
            >
              <img
                src={giz}
                style={{
                  height: "-webkit-fill-available",
                  width: "-webkit-fill-available",
                }}
                alt=""
              />
            </div>
            <div
              className="slide"
              onClick={() => openPartnerWebsite("https://cooperation.ca/fr/")}
            >
              <img
                src={canada}
                style={{
                  height: "-webkit-fill-available",
                  width: "-webkit-fill-available",
                }}
                alt=""
              />
            </div>
            <div
              className="slide"
              onClick={() => openPartnerWebsite("https://www.afdb.org/fr")}
            >
              <img
                src={bad}
                style={{
                  height: "-webkit-fill-available",
                  width: "-webkit-fill-available",
                }}
                alt=""
              />
            </div>
            <div
              className="slide"
              onClick={() => openPartnerWebsite("https://www.oecd.org/fr/")}
            >
              <img
                src={ocde}
                style={{
                  height: "-webkit-fill-available",
                  width: "-webkit-fill-available",
                }}
                alt=""
              />
            </div> */}
          </div>
        </div>
      )}
    </SectionFullContainer>
  );
}
