import styled from "styled-components";

export const HeaderComponent = styled("div")`
  // position: relative;
  // padding: 0.3em;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: #fff;
  flex-direction: column;
`;

export const BodyComponent = styled("div")`
  width: 100%;
  // height: 80%;
`;

export const HeaderSideComponent = styled("div")`
  // margin: 5px;
  display: flex;
  justify-content: ${(props) => props.position || "center"};
  align-items: center;
  transition: transform 0.3s ease-in-out;
`;

export const ItemMenu = styled("div")`
  margin: 0.5em;
  padding: 0.3em;
  font-size: 0.8vw;
  font-weight: 600;
  cursor: pointer;
  color: #000;
  &:hover {
    border-bottom: 2px solid #258925;
    // background-color: #fff;
  }
`;

export const ItemMenuOption = styled("div")`
  margin: 0.5em;
  padding: 0.6em;
  font-size: 12pt;
  border-radius: 5px;
  background-color: #2eab82;
  color: #fff;
  // border: 1px solid #2c3639;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  // &:hover {
  //   background-color: #fff;
  //   color: #2EAB82;
  // }
`;

export const ItemMenuAppName = styled("div")`
  margin: 0.5em;
  padding: 0.6em;
  font-size: 2.5vh;
  border-radius: 0.1em;
  // background-color: #f1eef9;
  color: #fff;
  cursor: pointer;
  display: flex;
`;

export const AppLogo = styled("img")`
  height: 3.7vw;
  margin: 0px 3px;
`;

export const TopContent = styled("div")`
  min-height: 67vh;
  display: flex;
  padding: 25px;
  background-color: #2eab82;
  margin-top: 12vh;
`;

export const TopContentSide = styled("div")`
  text-align: center;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  // font-family: "Poppins", sans-serif;
`;

export const SearchBarContainer = styled("div")`
  background-color: #fff;
  padding: 0.3em;
  border-radius: 50px;
  display: flex;
  // -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
  //   0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  // box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
  //   0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  &:hover {
  }
`;

export const SearchBarLeft = styled("div")`
  flex: 1;
  padding: 0.2em;
`;
export const SearchBarRight = styled("div")`
  border-radius: 50px;
  padding: 0.5em;
  background-color: #ffd100;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 1px;
  &:hover {
    // background-color: #2EAB82;
  }
`;

export const InputSearch = styled("input")`
  width: 100%;
  box-sizing: border-box;
  outline: none;
  border: none;
  // font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 0.7em;
  &:focus {
    border: none;
  }
`;

export const FooterComponent = styled("div")`
  // padding: 25px;
  background-color: #155841;
  display: flex;
  justify-content: center;
  color: #fff;
  // font-size: 10pt;
  flex-direction: column;
`;

export const FooterContent = styled("div")`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
`;

export const FooterMobileContent = styled("div")`
display: grid;
grid-template-columns: 1fr;
grid-template-rows: repeat(3, 1fr);
grid-column-gap: 0px;
grid-row-gap: 0px;
`;

export const FooterSide = styled("div")`
  padding: 10px;
  margin: 10px;
  // background-color: rgba(255, 255, 255, 0.2);
  border-radius: 15px;
`;

export const FooterSideTitle = styled("div")`
  font-size: 13pt;
  font-weight: bold;
  display: flex;
  justify-content: center;
`;

export const FooterSideItem = styled("div")`
  margin: 5px;
  padding: 5px;
  // background-color: rgba(255, 204, 0, 0.4);
  border-radius: 7px;
  display: flex;
  font-size: 12pt;
`;

export const TopContentPartners = styled("div")`
  flex: 1;
  margin: 5px;
  padding: 5px;
  background-color: red;
`;

export const MenuMobileIconContainer = styled("div")`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  margin-right: 5vw;
`;

export const MenuMobileIcon = styled("div")`
  padding: 5px 15px;
  background-color: #cbfbe6;
  border-radius: 5px;
  cursor: pointer;
`;
