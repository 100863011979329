import React, { useEffect, useState } from "react";
import { AppContainer } from "../../../styles/containers";
import HeaderApp from "../../../containers/header";
import { BodyComponent } from "../../../styles/home";
import CustomHeaderTitle from "../../../components/extras/custom-header-title";
import SectionPartners from "../../../components/section-partners";
import SectionNewsLetter from "../../../components/section-newsletter";
import FooterApp from "../../../containers/footer";
import SectionGeneralNotice from "../../../components/section-general-notice";
import { GetRecapData } from "../../../configuration/api";
import { useParams } from "react-router";
import { Helmet } from "react-helmet";

export default function GeneralNoticePage() {
  const { searchTerm } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [recapData, setRecapData] = useState(null);

  useEffect(() => {
    getRecapData();
  }, []);

  useEffect(() => {
    if (recapData) setIsLoading(false);
  }, [recapData]);

  const getRecapData = async () => {
    try {
      setIsLoading(true);
      const response = await GetRecapData();
      const { success, data, error } = response.data;
      if (success) {
        setRecapData(data);
      }
    } catch (error) {
      console.log({ error });
      setRecapData({});
    }
  };
  return (
    <>
      <Helmet>
        <title>Appels d'Offre - Trouvez les Meilleures Opportunités</title>
        <meta
          name="description"
          content="Explorez notre collection d'appels d'offre actuels et trouvez les meilleures opportunités pour votre entreprise."
        />
        <meta
          property="og:title"
          content="Appels d'Offre - Opportunités de Marché"
        />
        <meta
          property="og:description"
          content="Découvrez une variété d'appels d'offre dans différents secteurs et saisissez les meilleures opportunités pour votre entreprise."
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/faceshop/image/upload/c_fit,h_150,w_150/v1717166302/Group_11202_fhjeo0.png"
        />
        <meta
          property="og:url"
          content="https://www.lesmarchés.com/appels-d-offres"
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <AppContainer
        style={{
          backgroundColor: "#EFF5F3",
        }}
      >
        <HeaderApp />
        <BodyComponent>
          <CustomHeaderTitle title="AVIS D'APPELS D'OFFRES" />
          {isLoading ? (
            <div
              className="in-container-loader"
              style={{ marginBottom: "100px" }}
            >
              <div className="spinner-loader"></div>
            </div>
          ) : (
            <>
              <SectionGeneralNotice
                recapData={recapData}
                defaultSearchTerm={searchTerm}
              />
              <SectionPartners />
              <SectionNewsLetter />
            </>
          )}
        </BodyComponent>
        {!isLoading && <FooterApp />}
      </AppContainer>
    </>
  );
}
