import React, { Component } from "react";
import PartnersContext from "./Partners";

export default class PartnersProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPartner: null,
    };
  }

  render() {
    return (
      <PartnersContext.Provider
        value={{
          newPartner: this.state.newPartner,
          setNewPartner: (newPartner) => {
            this.setState({
              newPartner,
            });
          },
        }}
      >
        {this.props.children}
      </PartnersContext.Provider>
    );
  }
}
