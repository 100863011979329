import React from "react";
import {
  BodyCardGeneralNoticeContainer,
  InLineContentDataCardGeneralNotice,
  LabelCardGeneralNotice,
  ValueCardGeneralNotice,
} from "../../styles/cards";
import {
  getAtrributionTypeTraduction,
  getProcurementTypeTraduction,
  isFieldWithValue,
} from "../../utils";

export default function AwareNoticeDetails({ data }) {
  const getAssignee = () => {
    try {
      let value = "";
      if (!data?.lots?.length) return "";
      for (const item of data?.lots) {
        value += `${item.nameOfAssignee}, `;
      }
      return value;
    } catch (error) {
      console.log({ error });
      return "";
    }
  };

  return (
    <BodyCardGeneralNoticeContainer
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: "10px",
      }}
    >
      <InLineContentDataCardGeneralNotice>
        <LabelCardGeneralNotice
          style={{ color: "#000", fontSize: "8pt", marginRight: "5px" }}
        >
          TYPE D'ATTRIBUTION:
        </LabelCardGeneralNotice>
        <ValueCardGeneralNotice style={{ color: "#000" }}>
          {getAtrributionTypeTraduction[data?.type]}
        </ValueCardGeneralNotice>
      </InLineContentDataCardGeneralNotice>
      <InLineContentDataCardGeneralNotice>
        <LabelCardGeneralNotice
          style={{ color: "#000", fontSize: "8pt", marginRight: "5px" }}
        >
          MODE DE PASSATION:
        </LabelCardGeneralNotice>
        <ValueCardGeneralNotice style={{ color: "#000" }}>
          {data?.method?.toUpperCase()}
        </ValueCardGeneralNotice>
      </InLineContentDataCardGeneralNotice>
      <InLineContentDataCardGeneralNotice>
        <LabelCardGeneralNotice
          style={{ color: "#000", fontSize: "8pt", marginRight: "5px" }}
        >
          ATTRIBUTAIRE:
        </LabelCardGeneralNotice>
        <ValueCardGeneralNotice style={{ color: "#000" }}>
          {data?.lots?.length ? getAssignee() : data?.nameOfAssignee}
        </ValueCardGeneralNotice>
      </InLineContentDataCardGeneralNotice>
      {Boolean(data?.lots?.length) && (
        <InLineContentDataCardGeneralNotice>
          <LabelCardGeneralNotice
            style={{ color: "#000", fontSize: "8pt", marginRight: "5px" }}
          >
            NOMBRE DE LOTS:
          </LabelCardGeneralNotice>
          <ValueCardGeneralNotice style={{ color: "#000" }}>
            {data?.lots?.length}
          </ValueCardGeneralNotice>
        </InLineContentDataCardGeneralNotice>
      )}
      <InLineContentDataCardGeneralNotice
        style={{
          flexDirection: "column",
          alignItems: "unset",
          fontWeight: "bold",
        }}
      >
        <LabelCardGeneralNotice
          style={{ color: "#000", fontSize: "8pt", marginRight: "5px" }}
        >
          DÉNOMINATION: &nbsp; &nbsp;
        </LabelCardGeneralNotice>
        <ValueCardGeneralNotice style={{ color: "#000", fontWeight: "normal" }}>
          {data?.name}
        </ValueCardGeneralNotice>
      </InLineContentDataCardGeneralNotice>
      {Boolean(data?.lots?.length) &&
        data.lots.map((value, index) => (
          <InLineContentDataCardGeneralNotice
            style={{
              flexDirection: "column",
              alignItems: "unset",
              fontWeight: "bold",
              backgroundColor: "#EFF5F5",
              padding: "8px",
              borderRadius: "5px",
            }}
            key={index}
          >
            <LabelCardGeneralNotice
              style={{ color: "#000", fontSize: "8pt", marginRight: "5px", marginBottom: "10px" }}
            >
              LOT {index + 1} &nbsp; &nbsp;
            </LabelCardGeneralNotice>
            <ValueCardGeneralNotice
              style={{ color: "#000", fontWeight: "normal" }}
            >
                <InLineContentDataCardGeneralNotice>
                <LabelCardGeneralNotice
                  style={{ color: "#000", fontSize: "8pt", marginRight: "5px" }}
                >
                  TITRE DE L'OFFRE:
                </LabelCardGeneralNotice>
                <ValueCardGeneralNotice style={{ color: "#000" }}>
                  {value?.title}
                </ValueCardGeneralNotice>
              </InLineContentDataCardGeneralNotice>
              <InLineContentDataCardGeneralNotice>
                <LabelCardGeneralNotice
                  style={{ color: "#000", fontSize: "8pt", marginRight: "5px" }}
                >
                  ATTRIBUTAIRE:
                </LabelCardGeneralNotice>
                <ValueCardGeneralNotice style={{ color: "#000" }}>
                  {value?.nameOfAssignee}
                </ValueCardGeneralNotice>
              </InLineContentDataCardGeneralNotice>
              <InLineContentDataCardGeneralNotice>
                <LabelCardGeneralNotice
                  style={{ color: "#000", fontSize: "8pt", marginRight: "5px" }}
                >
                  ADRESSE:
                </LabelCardGeneralNotice>
                <ValueCardGeneralNotice style={{ color: "#000" }}>
                  {value?.addressOfAssignee}
                </ValueCardGeneralNotice>
              </InLineContentDataCardGeneralNotice>
              <InLineContentDataCardGeneralNotice>
                <LabelCardGeneralNotice
                  style={{ color: "#000", fontSize: "8pt", marginRight: "5px" }}
                >
                  MONTANT DE L'OFFRE:
                </LabelCardGeneralNotice>
                <ValueCardGeneralNotice style={{ color: "#000" }}>
                  {value?.offerAmount?.toLocaleString("fr-FR")} <i style={{ fontWeight: "normal"}}>{`(${value?.offerAmountInLetter})`}</i>
                </ValueCardGeneralNotice>
              </InLineContentDataCardGeneralNotice>
            </ValueCardGeneralNotice>
          </InLineContentDataCardGeneralNotice>
        ))}
    </BodyCardGeneralNoticeContainer>
  );
}
