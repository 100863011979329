import React from "react";
import { CustomFilterDateContainer } from "../../styles/containers";

export default function CustomFilterDate({ startDate, endDate, onDateChange }) {

  const handleChangeStartDate = (event) => {
    const { name, value } = event.target;
    onDateChange({ 
      startDate: value,
      endDate,
    })
  };

  const handleChangeEndDate = (event) => {
    const { name, value } = event.target;
    onDateChange({ 
      startDate,
      endDate: value,
    });
  };

  return (
    <CustomFilterDateContainer>
      <div>
        <input
          type="date"
          id="startDate"
          name="startDate"
          placeholder="Date de début"
          className="input-text"
          value={startDate || ""}
          onChange={handleChangeStartDate}
          style={{ marginRight: "5px", width: "-webkit-fill-available" }}
        />
      </div>
      <div>
        <input
          type="date"
          id="endDate"
          name="endDate"
          placeholder="Date de fin"
          className="input-text"
          value={endDate || ""}
          onChange={handleChangeEndDate}
          style={{ marginRight: "5px", width: "-webkit-fill-available" }}
          min={startDate}
        />
      </div>
    </CustomFilterDateContainer>
  );
}
