import React, { useState } from "react";
import {
  FooterComponent,
  FooterContent,
  FooterSide,
  FooterSideItem,
  FooterSideTitle,
} from "../styles/home";
import "../styles/footer.css";
import { FaPhone, FaWhatsapp } from "react-icons/fa6";
import { IoIosMail } from "react-icons/io";
import { ImWhatsapp } from "react-icons/im";
import { FaTwitter, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import "../styles/input.css";
import { Link } from "react-router-dom";
import {
  TbSquareRoundedNumber1Filled,
  TbSquareRoundedNumber2Filled,
  TbSquareRoundedNumber3Filled,
} from "react-icons/tb";
import { BsMailbox2Flag } from "react-icons/bs";
import { WindowsSizeBreakpoints, isMobileOrSmallScreen } from "../utils";
import { useWindowSize } from "../utils/useWindowSize";

export default function FooterApp() {
  const [isMobileDevice, _] = useState(isMobileOrSmallScreen());
  const { width } = useWindowSize();

  const onRegister = () => {
    console.log("test");
  };
  return (
    <FooterComponent>
      <div className="pg-footer">
        <footer className="footer">
          <svg
            className="footer-wave-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 100"
            preserveAspectRatio="none"
          >
            <path
              className="footer-wave-path"
              d="M851.8,100c125,0,288.3-45,348.2-64V0H0v44c3.7-1,7.3-1.9,11-2.9C80.7,22,151.7,10.8,223.5,6.3C276.7,2.9,330,4,383,9.8 c52.2,5.7,103.3,16.2,153.4,32.8C623.9,71.3,726.8,100,851.8,100z"
            ></path>
          </svg>
          <div className="footer-content">
            <div>
              <FooterContent style={{
                ...((isMobileDevice || Boolean(width <= WindowsSizeBreakpoints.medium)) ? {
                  gridTemplateColumns: "1fr",
                  gridTemplateRows: "repeat(3, 1fr)",
                  display: "contents"
                }: {
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gridTemplateRows: "1fr",
                })
              }}>
                <FooterSide>
                  <FooterSideTitle>Contacts</FooterSideTitle>
                  <FooterSideItem>
                    <FaPhone color="#fff" size={18} />
                    <a
                      href="tel: +221771646767"
                      className="item-footer"
                      style={{ color: "white" }}
                    >
                      &nbsp; +221 77 164 67 67
                    </a>
                  </FooterSideItem>
                  <FooterSideItem>
                    <IoIosMail color="#fff" size={20} />{" "}
                    <a href="mailto: contact@lesmarchés.com" className="item-footer">
                      &nbsp; contact@lesmarchés.com
                    </a>
                  </FooterSideItem>
                  <FooterSideItem>
                    <FaLocationDot color="#fff" size={18} />
                    <a className="item-footer" style={{ color: "white" }} href="https://www.google.com/maps/search/97,+Sacr%C3%A9-+Coeur+3,+Dakar/@14.7242989,-17.4861021,19.96z?authuser=0&entry=ttu">
                      &nbsp; 97, Sacré-Coeur 3 - Dakar (SENEGAL)
                    </a>
                  </FooterSideItem>
                  <FooterSideItem>
                    <BsMailbox2Flag color="#fff" size={18} />
                    <a className="item-footer" style={{ color: "white" }} href="https://www.google.com/maps/search/97,+Sacr%C3%A9-+Coeur+3,+Dakar/@14.7242989,-17.4861021,19.96z?authuser=0&entry=ttu">
                      &nbsp; BP: 15948, Fann-DAKAR (SENEGAL)
                    </a>
                  </FooterSideItem>
                </FooterSide>
                <FooterSide>
                  <FooterSideTitle>Réseaux sociaux</FooterSideTitle>
                  <FooterSideItem
                    style={{ backgroundColor: "rgba(255, 255, 255, 0.2)" }}
                  >
                    <FaWhatsapp color="#25d366" size={18} />
                    <a
                      href="https://whatsapp.com/"
                      className="btn-shine"
                      target="_blank"
                    >
                      WhatsApp
                    </a>
                  </FooterSideItem>
                  <FooterSideItem
                    style={{ backgroundColor: "rgba(255, 255, 255, 0.2)" }}
                  >
                    <FaTwitter color="#1da1f2" size={18} />
                    <a
                      href="https://twitter.com/"
                      className="btn-shine"
                      target="_blank"
                    >
                      Twitter
                    </a>
                  </FooterSideItem>
                  <FooterSideItem
                    style={{ backgroundColor: "rgba(255, 255, 255, 0.2)" }}
                  >
                    <FaLinkedinIn color="#007dbb" size={18} />
                    <a
                      href="https://linkedin.com/"
                      className="btn-shine"
                      target="_blank"
                    >
                      Linkedin
                    </a>
                  </FooterSideItem>
                  <FooterSideItem
                    style={{ backgroundColor: "rgba(255, 255, 255, 0.2)" }}
                  >
                    <FaFacebookF color="#2f55a4" size={18} />
                    <a
                      href="https://facebook.com/"
                      className="btn-shine"
                      target="_blank"
                    >
                      Facebook
                    </a>
                  </FooterSideItem>
                </FooterSide>
                <FooterSide>
                  <FooterSideTitle>
                    3 Bonnes raisons de nous suivre
                  </FooterSideTitle>
                  <FooterSideItem>
                    <a
                      href="tel: +221771646767"
                      className="item-footer"
                      style={{ color: "white" }}
                    >
                      <TbSquareRoundedNumber1Filled color="#fff" size={18} />{" "}
                      &nbsp; Accès privilégié à des marchés et appels d'offres
                      dans le secteur public et privé
                    </a>
                  </FooterSideItem>

                  <FooterSideItem>
                    <a
                      href="tel: +221771646767"
                      className="item-footer"
                      style={{ color: "white" }}
                    >
                      <TbSquareRoundedNumber2Filled color="#fff" size={18} />{" "}
                      &nbsp; Un système de notifications instantané pour les
                      abonnés, donnant une longueur d'avance sur vos concurrents
                    </a>
                  </FooterSideItem>

                  <FooterSideItem>
                    <a
                      href="tel: +221771646767"
                      className="item-footer"
                      style={{ color: "white" }}
                    >
                      <TbSquareRoundedNumber3Filled color="#fff" size={18} />{" "}
                      &nbsp; Un moteur de recherche Simple Rapide et Performant
                    </a>
                  </FooterSideItem>
                </FooterSide>
              </FooterContent>
            </div>
            <div className="footer-social-links">
              {" "}
              <svg
                className="footer-social-amoeba-svg"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 236 54"
              >
                <path
                  className="footer-social-amoeba-path"
                  d="M223.06,43.32c-.77-7.2,1.87-28.47-20-32.53C187.78,8,180.41,18,178.32,20.7s-5.63,10.1-4.07,16.7-.13,15.23-4.06,15.91-8.75-2.9-6.89-7S167.41,36,167.15,33a18.93,18.93,0,0,0-2.64-8.53c-3.44-5.5-8-11.19-19.12-11.19a21.64,21.64,0,0,0-18.31,9.18c-2.08,2.7-5.66,9.6-4.07,16.69s.64,14.32-6.11,13.9S108.35,46.5,112,36.54s-1.89-21.24-4-23.94S96.34,0,85.23,0,57.46,8.84,56.49,24.56s6.92,20.79,7,24.59c.07,2.75-6.43,4.16-12.92,2.38s-4-10.75-3.46-12.38c1.85-6.6-2-14-4.08-16.69a21.62,21.62,0,0,0-18.3-9.18C13.62,13.28,9.06,19,5.62,24.47A18.81,18.81,0,0,0,3,33a21.85,21.85,0,0,0,1.58,9.08,16.58,16.58,0,0,1,1.06,5A6.75,6.75,0,0,1,0,54H236C235.47,54,223.83,50.52,223.06,43.32Z"
                ></path>
              </svg>
              <a
                className="footer-social-link whatsapp a"
                href="https://whatsapp.com/"
                target="_blank"
              >
                <span className="hidden-link-text">WhatsApp</span>
                <svg
                  className="footer-social-icon-path"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 47 78"
                >
                  <path d="M25,2C12.318,2,2,12.318,2,25c0,3.96,1.023,7.854,2.963,11.29L2.037,46.73c-0.096,0.343-0.003,0.711,0.245,0.966 C2.473,47.893,2.733,48,3,48c0.08,0,0.161-0.01,0.24-0.029l10.896-2.699C17.463,47.058,21.21,48,25,48c12.682,0,23-10.318,23-23 S37.682,2,25,2z M36.57,33.116c-0.492,1.362-2.852,2.605-3.986,2.772c-1.018,0.149-2.306,0.213-3.72-0.231 c-0.857-0.27-1.957-0.628-3.366-1.229c-5.923-2.526-9.791-8.415-10.087-8.804C15.116,25.235,13,22.463,13,19.594 s1.525-4.28,2.067-4.864c0.542-0.584,1.181-0.73,1.575-0.73s0.787,0.005,1.132,0.021c0.363,0.018,0.85-0.137,1.329,1.001 c0.492,1.168,1.673,4.037,1.819,4.33c0.148,0.292,0.246,0.633,0.05,1.022c-0.196,0.389-0.294,0.632-0.59,0.973 s-0.62,0.76-0.886,1.022c-0.296,0.291-0.603,0.606-0.259,1.19c0.344,0.584,1.529,2.493,3.285,4.039 c2.255,1.986,4.158,2.602,4.748,2.894c0.59,0.292,0.935,0.243,1.279-0.146c0.344-0.39,1.476-1.703,1.869-2.286 s0.787-0.487,1.329-0.292c0.542,0.194,3.445,1.604,4.035,1.896c0.59,0.292,0.984,0.438,1.132,0.681 C37.062,30.587,37.062,31.755,36.57,33.116z"></path>
                </svg>
              </a>
              <a
                className="footer-social-link twitter a"
                href="https://twitter.com/"
                target="_blank"
              >
                <span className="hidden-link-text">Twitter</span>
                <svg
                  className="footer-social-icon-svg"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 26 26"
                >
                  <path
                    className="footer-social-icon-path"
                    d="M 25.855469 5.574219 C 24.914063 5.992188 23.902344 6.273438 22.839844 6.402344 C 23.921875 5.75 24.757813 4.722656 25.148438 3.496094 C 24.132813 4.097656 23.007813 4.535156 21.8125 4.769531 C 20.855469 3.75 19.492188 3.113281 17.980469 3.113281 C 15.082031 3.113281 12.730469 5.464844 12.730469 8.363281 C 12.730469 8.773438 12.777344 9.175781 12.867188 9.558594 C 8.503906 9.339844 4.636719 7.246094 2.046875 4.070313 C 1.59375 4.847656 1.335938 5.75 1.335938 6.714844 C 1.335938 8.535156 2.261719 10.140625 3.671875 11.082031 C 2.808594 11.054688 2 10.820313 1.292969 10.425781 C 1.292969 10.449219 1.292969 10.46875 1.292969 10.492188 C 1.292969 13.035156 3.101563 15.15625 5.503906 15.640625 C 5.0625 15.761719 4.601563 15.824219 4.121094 15.824219 C 3.78125 15.824219 3.453125 15.792969 3.132813 15.730469 C 3.800781 17.8125 5.738281 19.335938 8.035156 19.375 C 6.242188 20.785156 3.976563 21.621094 1.515625 21.621094 C 1.089844 21.621094 0.675781 21.597656 0.265625 21.550781 C 2.585938 23.039063 5.347656 23.90625 8.3125 23.90625 C 17.96875 23.90625 23.25 15.90625 23.25 8.972656 C 23.25 8.742188 23.246094 8.515625 23.234375 8.289063 C 24.261719 7.554688 25.152344 6.628906 25.855469 5.574219 "
                  ></path>
                </svg>
              </a>
              <a
                className="footer-social-link linkedin a"
                href="https://linkedin.com/"
                target="_blank"
              >
                <span className="hidden-link-text">Linkedin</span>
                <svg
                  className="footer-social-icon-svg"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 30 30"
                >
                  <path
                    className="footer-social-icon-path"
                    d="M9,25H4V10h5V25z M6.501,8C5.118,8,4,6.879,4,5.499S5.12,3,6.501,3C7.879,3,9,4.121,9,5.499C9,6.879,7.879,8,6.501,8z M27,25h-4.807v-7.3c0-1.741-0.033-3.98-2.499-3.98c-2.503,0-2.888,1.896-2.888,3.854V25H12V9.989h4.614v2.051h0.065 c0.642-1.18,2.211-2.424,4.551-2.424c4.87,0,5.77,3.109,5.77,7.151C27,16.767,27,25,27,25z"
                  ></path>
                </svg>
              </a>
              <a
                className="footer-social-link facebook a"
                href="https://facebook.com/"
                target="_blank"
              >
                <span className="hidden-link-text">Facebook</span>
                <svg
                  className="footer-social-icon-svg"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 50 70"
                >
                  <path d="M25,3C12.85,3,3,12.85,3,25c0,11.03,8.125,20.137,18.712,21.728V30.831h-5.443v-5.783h5.443v-3.848 c0-6.371,3.104-9.168,8.399-9.168c2.536,0,3.877,0.188,4.512,0.274v5.048h-3.612c-2.248,0-3.033,2.131-3.033,4.533v3.161h6.588 l-0.894,5.783h-5.694v15.944C38.716,45.318,47,36.137,47,25C47,12.85,37.15,3,25,3z"></path>
                </svg>
              </a>
            </div>
          </div>
          <div className="footer-copyright">
            <div className="footer-copyright-wrapper">
              <p className="footer-copyright-text">
                <a className="footer-copyright-link a" href="#" target="_self">
                  {" "}
                  {/* ©2020. | Designed By: Pooja Nahelia. | All rights reserved.{" "} */}
                  ©2024. | Plateforme des marchés publics & privés | All rights
                  reserved.
                </a>
              </p>
            </div>
          </div>
        </footer>
      </div>
    </FooterComponent>
  );
}
