import React, { Component } from "react";
import ProcurementContext from "./Procurement";

export default class ProcurementProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      procurementPlan: null,
      provisionalNoticeAward: null,
      generalNotice: null,
      lot: null,
      generalLot: null,
      newsletter: null
    };
  }

  render() {
    return (
      <ProcurementContext.Provider
        value={{
          procurementPlan: this.state.procurementPlan,
          setProcurementPlan: (procurementPlan) => {
            this.setState({
              procurementPlan,
            });
          },

          provisionalNoticeAward: this.state.provisionalNoticeAward,
          setProvisionalNoticeAward: (provisionalNoticeAward) => {
            this.setState({
              provisionalNoticeAward,
            });
          },

          generalNotice: this.state.generalNotice,
          setGeneralNotice: (generalNotice) => {
            this.setState({
              generalNotice,
            });
          },

          lot: this.state.lot,
          setLot: (lot) => {
            this.setState({
              lot,
            });
          },

          generalLot: this.state.generalLot,
          setGeneralLot: (generalLot) => {
            this.setState({
              generalLot,
            });
          },

          newsletter: this.state.newsletter,
          setNewsletter: (newsletter) => {
            this.setState({
              newsletter,
            });
          },
        }}
      >
        {this.props.children}
      </ProcurementContext.Provider>
    );
  }
}
