export const truncateText = (text = "", endTo = 50) =>
  text?.length > endTo ? `${text.substr(0, endTo)}...` : text;

export const isValidURL = (url) => {
  const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
  return urlPattern.test(url);
};

export const isFieldWithValue = (field) =>
  field && field?.replace(/\s/g, "")?.length;

export const formatDateToDDMMYYYY = (value) => {
  try {
    if (value) {
      const date = new Date(value);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const day = String(date.getDate()).padStart(2, "0");
      if (!day || !year || !month) return "-";
      return `${day}-${month}-${year}`;
    }
    return null;
  } catch (error) {
    // console.log({ error })
    return "";
  }
};

export const getLimitDateOfProcurement = (value, duration) => {
  try {
    if (duration) {
      const limit = new Date(value);
      limit.setDate(limit.getDate() + duration);
      return limit;
    }
    return null;
  } catch (error) {
    console.log({ error });
    return null;
  }
};

export const EProcurementType = {
  FOURNITURES: "FOURNITURES",
  SERVICES_COURANTS: "SERVICES_COURANTS",
  TRAVAUX: "TRAVAUX",
  PRESTATIONS_INTELLECTUELLES: "PRESTATIONS_INTELLECTUELLES",
};

export const EAtrributionType = {
  PROVISIONAL: "PROVISIONAL",
  DEFINITIVE: "DEFINITIVE",
};

export const getProcurementTypeTraduction = {
  TRAVAUX: "TRAVAUX",
  FOURNITURES: "FOURNITURES",
  SERVICES_COURANTS: "SERVICES COURANTS",
  PRESTATIONS_INTELLECTUELLES: "PRESTATIONS INTELLECTUELLES",
};

export const getAtrributionTypeTraduction = {
  PROVISIONAL: "PROVISOIRE",
  DEFINITIVE: "DEFINITIVE",
};

export const formatNumber2Gits = (value = 0) =>
  value > 9 ? `${value}` : `0${value}`;

export const ProcurementMethod = [
  {
    name: "DRP Simple",
    duration: 0,
    delay: 3,
  },
  {
    name: "DRP à compétition restreinte",
    duration: 15,
    delay: 3,
  },
  {
    name: "DRP à compétition ouverte",
    duration: 15,
    delay: 3,
  },
  {
    name: "Appels d'offres ouverts",
    duration: 30,
    delay: 5,
  },
  {
    name: "Appels d'offres",
    duration: 30,
    delay: 5,
  },
  {
    name: "Appels d'offres avec préqualification",
    duration: 30,
    delay: 5,
  },
  {
    name: "Appels d'offres ouverts en deux étapes",
    duration: 30,
    delay: 5,
  },
  {
    name: "Appels d'offres avec concours",
    duration: 30,
    delay: 5,
  },
  {
    name: "Appel public à Manifestation d'intérêt (AMI)",
    duration: 15,
    delay: 5,
  },
  {
    name: "Appels d'offres international",
    duration: 45,
    delay: 5,
  },
  {
    name: "Appels d'offres restreints",
    duration: 0,
    delay: 5,
  },
  {
    name: "Entente direct",
    duration: 0,
    delay: 0,
  },
];

export const Currencies = [
  {
    name: "FRANC CFA",
    value: "XOF",
  },
  {
    name: "EURO",
    value: "£",
  },
  {
    name: "DOLLAR",
    value: "$",
  },
];

export const getRemainingDays = (end) => {
  try {
    const today = new Date();
    const endDate = new Date(end);
    const difference = endDate.getTime() - today.getTime();
    const millisecondsInADay = 1000 * 60 * 60 * 24;
    return Math.round(difference / millisecondsInADay) + 1;
  } catch (error) {
    console.log({ error });
    return null;
  }
};

export const formatDateFilter = (inputDate) => {
  try {
    const parts = inputDate.split("-");
    const formattedDate = `${parts[2]}/${parts[1]}/${parts[0]}`;
    return formattedDate;
  } catch (error) {
    return null;
  }
};

export const numberWithFrenchSeparator = (number) => {
  return number.toLocaleString("fr-FR");
};

export const PUBS = [
  {
    code: "1",
    image: "https://www.emploi.ci/sites/emploi.ci/files/logo/photo_2022-07-18_15-54-23.jpg",
    name: "yango",
  },
  {
    code: "2",
    image: "https://kireneavecorange.sn/IMG/png/490.png",
    name: "kirene",
  },
  {
    code: "3",
    image:
      "https://www.senfinances.org/app/themes/mfb/dist/images/ministere-des-finances-et-du-budget-logo-cacao.png",
    name: "mfin",
  },
  {
    code: "4",
    image:
      "https://image.isu.pub/210809103956-d8ce40a0822ccca5bba6769ea08b2bb6/jpg/page_1.jpg",
    name: "business",
  },
  {
    code: "5",
    image:
      "https://www.ubardc.com/wp-content/uploads/sites/11/2021/06/OrangeMoney_UBACard-1.jpg",
    name: "card",
  },
];

export const isMobileOrSmallScreen = () =>
  /Mobi|Android/i.test(navigator.userAgent) || window.innerWidth <= 768;

export const WindowsSizeBreakpoints = {
  small: 576,
  medium: 768,
  large: 992,
  xlarge: 1200,
};
