import styled from "styled-components";

export const CardContainer = styled("div")`
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  margin: 10px;
`;

export const CardProcurementTypeStyle = styled("div")`
  width: 100%;
  margin: 1%;
  color: #fff;
  cursor: pointer;
  display: contents;
  background-size: 200% 200%;

  -webkit-box-shadow: 0px 2px 4px -1px rgba(193, 193, 193, 0.2),
    0px 4px 5px 0px rgba(193, 193, 193, 0.14),
    0px 1px 10px 0px rgba(193, 193, 193, 0.12);
  box-shadow: 0px 2px 4px -1px rgba(193, 193, 193, 0.2),
    0px 4px 5px 0px rgba(193, 193, 193, 0.14),
    0px 1px 10px 0px rgba(193, 193, 193, 0.12);
  &:hover {
    // background-color: rgba(37, 137, 104);
    // color: white;
    // background-color: linear-gradient(to bottom, #2EAB82 0%, #ffffff 100%); /* Change background color from top to bottom */
    // linear-gradient(to top, #258968 51%, transparent 50%);
    // background-position: 0 100%;
    -webkit-transition: background-position 300ms, color 300ms ease,
      border-color 300ms ease;
    -moz-transition: background-position 300ms, color 300ms ease,
      border-color 300ms ease;
    -ms-transition: background-position 300ms, color 300ms ease,
      border-color 300ms ease;
    -o-transition: background-position 300ms, color 300ms ease,
      border-color 300ms ease;
    transition: background-position 300ms, color 300ms ease,
      border-color 300ms ease;
    // width: 23%;
  }
  @media (max-width: 800px) {
    min-width: 45%;
  }
  @media (max-width: 550px) {
    min-width: 99%;
  }
`;
export const CardProcurementTypeBody = styled("div")`
  // border-radius: 5px;
  display: flex;
  flex-direction: column;
  // padding: 0.8%;
  height: 100%;
  background-color: ${(props) => props.color || "white"};
  &:hover {
    background-color: white;
    color: black;
  }
`;
export const CardProcurementTypeTag = styled("div")`
  width: 55px;
  height: 55px;
  background: #ffd100;
  border-radius: 100%;
  display: flex;
  justify-content: center;
`;

export const CardProcurementTypeContent = styled("div")`
  padding: 0.1em;
`;

export const TitleCard = styled("span")`
  font-size: 13pt;
  font-weight: 600;
  text-align: center;
`;

export const CardRecapContainer = styled("div")`
  flex: 1;
  padding: 5px;
  margin: 0px;
  background-color: ${(props) => props.color};
  -webkit-box-shadow: 7px 11px 15px 0px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  box-shadow: 7px 11px 15px 0px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  &:hover {
    background-color: #ffcc00;
    cursor: pointer;
    color: black;
  }
`;

export const CardResourceStyle = styled("div")`
  width: 20%;
  padding: 1.8%;
  margin: 2px;
  background-color: #258968;
  cursor: pointer;
  border-radius: 1px;
  &:hover {
    -webkit-box-shadow: 0px 2px 4px -1px rgba(37, 137, 104, 0.2),
      0px 4px 5px 0px rgba(37, 137, 104, 0.14),
      0px 1px 10px 0px rgba(37, 137, 104, 0.12);
    box-shadow: 0px 2px 4px -1px rgba(37, 137, 104, 0.2),
      0px 4px 5px 0px rgba(37, 137, 104, 0.14),
      0px 1px 10px 0px rgba(37, 137, 104, 0.12);
    background-color: #2eab82;
  }
  @media (max-width: 800px) {
    min-width: 45%;
  }
  @media (max-width: 550px) {
    min-width: 99%;
  }
`;

export const ContentCardResource = styled("div")`
  display: flex;
  padding: 5px;
  align-items: center;
`;

export const IconCardResource = styled("div")`
  display: flex;
  background-color: white;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
`;

export const TitleCardResource = styled("div")`
  color: #fff;
  margin-left: 5px;
  font-size: 12pt;
`;

export const CardLatestContainer = styled("div")`
  margin: 5px 0px 5px 5px;
  padding: 5px;
  display: flex;
`;

export const CardLatestImg = styled("div")`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  border-radius: 5px;
`;

export const TitleLatest = styled("span")`
  font-size: 9pt;
  font-weight: bold;
`;

export const CardPreviewGeneralNoticeStyle = styled("div")`
  background-color: rgba(37, 137, 104, 0.1);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 3fr 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin: 5px;
  padding: 5px;
  // -webkit-box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
  //   0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  // box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
  //   0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  &: hover {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
`;

export const CardPreviewGeneralNoticeHeader = styled("div")`
  padding: 5px;
  display: flex;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
`;

export const CardPreviewGeneralNoticeBody = styled("div")`
  // background-color: yellow;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CardPreviewGeneralNoticeFooter = styled("div")`
  padding: 5px;
  display: flex;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
`;

export const Tag = styled("div")`
  padding: 3px;
  font-size: 8pt;
  border-radius: 5px;
  background-color: ${(props) => props.color};
  color: #000;
  display: flex;
  justify-content: center;
`;

export const CardProcurementTitle = styled("div")`
  font-size: 14pt;
  padding: 2px;
  color: #000;
  text-align: center;
`;

export const CardProcurementDescription = styled("div")`
  font-size: 10pt;
  padding: 4px;
  color: #000;
`;

export const CardRecapAssistanceContainer = styled("div")`
  margin: 15px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  -webkit-box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
`;

export const CardPubContainer = styled("div")`
  padding: 5px;
  border: 1px solid #ebebeb;
  margin: 10px;
  width: 65%;
`;

export const ContainerContactInfo = styled("div")`
  background-color: ${(props) => props.color};
  // border-radius: 5px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
`;

export const ContainerContactInfoBody = styled("div")`
  display: grid;
  grid-template-columns: 1fr 5fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  align-items: center;
`;

export const ContainerContactInfoData = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContactInfosHeader = styled("div")`
  display: grid;
  grid-template-columns: 1fr 1.75fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  width: 100%
`;

export const ContactHeaderMessage = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: #258968;
  color: #258968;
  font-weight: bold;
  // font-style: italic;²
`;

export const Flash = styled("div")`
  animation-duration: 2s;
  animation-name: flash;
  animation-iteration-count: infinite;
  transition: none;
  color: #000;
  font-size: 10pt;
  background-color: ${(props) => props.color};
  padding: 0px 5px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CardGeneralNoticeContainer = styled("div")`
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 0px;
  // padding: 10px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 2fr 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -webkit-box-shadow: 10px 10px 5px 5px rgba(224, 229, 227, 1);
  -moz-box-shadow: 10px 10px 5px 5px rgba(224, 229, 227, 1);
  box-shadow: 10px 10px 5px 5px rgba(224, 229, 227, 1);
  cursor: pointer;
  &:hover {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
`;

export const HeaderCardGeneralNoticeContainer = styled("div")`
  // padding: 10px;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
`;

export const BodyCardGeneralNoticeContainer = styled("div")`
  background-color: white;
`;

export const FooterCardGeneralNoticeContainer = styled("div")`
  background-color: white;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
`;

export const LabelCardGeneralNotice = styled("div")`
  font-size: 9pt;
  color: #fff;
`;

export const ValueCardGeneralNotice = styled("div")`
  font-size: 10pt;
  font-weight: bold;
  color: #fff;
`;

export const ContentDataCardGeneralNotice = styled("div")`
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const InLineContentDataCardGeneralNotice = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;

export const InColumnContentDataCardGeneralNotice = styled("div")`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  width: -webkit-fill-available;
`;

export const CardOpportunityContainer = styled("div")`
  background-color: white;
  display: grid;
  grid-template-columns: 0fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin: 15px;
  padding: 5px;
  border-bottom: 1px solid #ebebeb;
  &:hover {
    //   -webkit-box-shadow: 10px 10px 5px 5px rgba(224, 229, 227, 1);
    // -moz-box-shadow: 10px 10px 5px 5px rgba(224, 229, 227, 1);
    // box-shadow: 10px 10px 5px 5px rgba(224, 229, 227, 1);
  }
`;

export const CardOpportunityImageContainer = styled("div")`
  width: 15vw;
  height: 10vw;
  display: flex;
  justify-content: center;
  align-content: center;
`;

export const CardOpportunityContent = styled("div")``;

export const CardOpportunityTitle = styled("div")`
  font-size: 14pt;
  font-weight: bold;
  color: #374b42;
  margin-bottom: 25px;
`;

export const CardOpportunityDescription = styled("div")`
  font-size: 12pt;
  color: #000;
`;
