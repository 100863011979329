import "./App.css";
import "./styles/animations.css";
import "./styles/card.css";
import "./styles/dropdown.css";
import "./styles/footer.css";
import "./styles/forms.css";
import "./styles/input.css";
import "./styles/latest.css";
import "./styles/login.css";
import "./styles/modal.css";
import "./styles/partners.css";
import "./styles/procurement.css";
import "./styles/dashboard.css";
import "./styles/animated-text.css";
import "./styles/collapse.css";
import "draft-js/dist/Draft.css";

import { Route, Routes } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { lazy, Suspense } from "react";
import NewsProvider from "./configuration/contexts/news/NewsProvider";
import ScrollToTop from "./utils/ScrollToTop";
import ProcurementProvider from "./configuration/contexts/news/ProcurementProvider";
import GeneralNoticePage from "./navigation/pages/procurements/general-notice-page";
import ProcurementPlanPage from "./navigation/pages/procurements/procurement-plan";
import AwareNoticePage from "./navigation/pages/procurements/aware-notice-page";
import OpportunityProvider from "./configuration/contexts/news/OpportunityProvider";
import Opportunities from "./navigation/pages/opportunities";
import PartnersProvider from "./configuration/contexts/news/PartnersProvider";
import NewsDetails from "./navigation/pages/news-details";
import OpportunityDetails from "./navigation/pages/opportunity-details";
import PubsProvider from "./configuration/contexts/news/PubsProvider";

const Audit = lazy(() => import("./navigation/pages/audit"));
const News = lazy(() => import("./navigation/pages/news"));
const DcmpInfos = lazy(() => import("./navigation/pages/dcmp"));
const ArcopInfos = lazy(() => import("./navigation/pages/arcop"));
const CdmpInfos = lazy(() => import("./navigation/pages/cdmp"));
const Formation = lazy(() => import("./navigation/pages/formation"));
const Finance = lazy(() => import("./navigation/pages/finance"));
const Login = lazy(() => import("./navigation/pages/login"));
const DashBoard = lazy(() => import("./navigation/pages/dashboard"));
const Home = lazy(() => import("./navigation/pages/home"));
const Assistance = lazy(() => import("./navigation/pages/assistance"));

function App() {
  return (
    <ProcurementProvider>
      <OpportunityProvider>
        <NewsProvider>
          <PartnersProvider>
            <PubsProvider>
              <ScrollToTop />
              <Suspense
                fallback={
                  <div className="full-screen-loader">
                    <div className="loader"></div>
                  </div>
                }
              >
                <Routes>
                  <Route index element={<Home />} path="/" />
                  <Route element={<Home />} path="/home" />
                  <Route element={<Assistance />} path="/assistance" />
                  <Route element={<Formation />} path="/formation" />
                  <Route element={<Audit />} path="/audit" />
                  <Route element={<News />} path="/news" />
                  <Route element={<NewsDetails />} path="/news/:code" />
                  <Route element={<DcmpInfos />} path="/authority/dcmp" />
                  <Route element={<ArcopInfos />} path="/authority/arcop" />
                  <Route element={<CdmpInfos />} path="/authority/cdmp" />
                  {/* <Route element={<Ppp />} path="/ppp" /> */}
                  <Route element={<Finance />} path="/financement" />
                  <Route element={<Login />} path="/login" />
                  <Route element={<DashBoard />} path="/dashboard" />
                  <Route
                    element={<GeneralNoticePage />}
                    path="/appels-d-offres"
                  />
                  <Route
                    element={<GeneralNoticePage />}
                    path="/appels-d-offres/:searchTerm"
                  />
                  <Route
                    element={<ProcurementPlanPage />}
                    path="/plan-de-marchés"
                  />
                  <Route
                    element={<ProcurementPlanPage />}
                    path="/plan-de-marchés/:type"
                  />
                  <Route
                    element={<AwareNoticePage />}
                    path="/avis-d-attributions"
                  />
                  <Route
                    element={<AwareNoticePage />}
                    path="/avis-d-attributions/:type"
                  />

                  <Route element={<Opportunities />} path="/opportunities" />
                  <Route
                    element={<OpportunityDetails />}
                    path="/opportunities/:code"
                  />
                </Routes>
              </Suspense>
            </PubsProvider>
          </PartnersProvider>
        </NewsProvider>
      </OpportunityProvider>
    </ProcurementProvider>
  );
}

export default App;
