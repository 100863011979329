import React from 'react'
import { CardPubContainer } from '../../styles/cards'

export default function CardPub({ urlImg = "", name = "", borderColor = "#ebebeb"}) {
  return (
    <CardPubContainer className='animate__animated animate__flash' style={{ borderColor}}>
        <img src={urlImg} alt={name} style={{ width: "100%"}}/>
    </CardPubContainer>
  )
}
