import React, { useEffect, useRef, useState } from "react";
import {
  ContainerCardsProcurement,
  ContainerFilterOption,
  ContainerFilterProcurement,
  ContainerPubs,
  FilterItem,
  ProcurementDataContainer,
  SectionContainer,
} from "../styles/containers";
import CustomCollapse from "./extras/custom-collapse";
import {
  EAtrributionType,
  WindowsSizeBreakpoints,
  formatDateFilter,
  formatDateToDDMMYYYY,
  getAtrributionTypeTraduction,
  isFieldWithValue,
  isMobileOrSmallScreen,
} from "../utils";
import CustomFilterDate from "./extras/custom-filter-date";
import CustomFilterSearch from "./extras/custom-filter-search";
import { GetAwareNotices, GetProcurementsPlans, GetPubs } from "../configuration/api";
import CustomPagination from "./extras/pagination";
import { ItemMenuOption } from "../styles/home";
import { useDidMountEffect } from "../utils/useDidMountEffect";
import CardAwareNotice from "./cards/card-aware-notice";
import CardPub from "./cards/card-pub";
import { useWindowSize } from "../utils/useWindowSize";
import ModalCard from "./modals/modal-card";
import HeaderCardDetails from "./details/header-card-details";
import FooterCardDetails from "./details/footer-card-details";
import AwareNoticeDetails from "./details/aware-notice-details";

export default function SectionAwareNoticePage({
  recapData,
  defaultSelectedType = "",
}) {
  const refFilter = useRef(null);
  const [itemsCollapse, setItemsCollapse] = useState([
    {
      name: getAtrributionTypeTraduction.PROVISIONAL,
      count: recapData?.procurement_plans_travaux,
      value: EAtrributionType.PROVISIONAL,
    },
    {
      name: getAtrributionTypeTraduction.DEFINITIVE,
      count: recapData?.procurement_plans_fournitures,
      value: EAtrributionType.DEFINITIVE,
    },
  ]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(8);
  const [searchTerm, setSearchTerm] = useState("");
  const [procurements, setProcurements] = useState(null);
  const [publicationDate, setPublicationDate] = useState({
    startDate: null,
    endDate: null,
  });
  const [selectedItem, setSelectedItem] = useState(null);
  const [openModalData, setOpenModalData] = useState(false);
  const [checkedTypes, setCheckedTypes] = useState(
    defaultSelectedType?.length &&
      [EAtrributionType.DEFINITIVE, EAtrributionType.PROVISIONAL].includes(
        defaultSelectedType.toLocaleUpperCase()
      )
      ? [defaultSelectedType.toUpperCase()]
      : []
  );
  const [pubs, setPubs] = useState([]);
  const [isMobileDevice, _] = useState(isMobileOrSmallScreen());
  const { width } = useWindowSize();

  useEffect(() => {
    getPubs();
  }, []);

  useDidMountEffect(() => {
    if (
      [EAtrributionType.DEFINITIVE, EAtrributionType.PROVISIONAL].includes(
        defaultSelectedType.toLocaleUpperCase()
      )
    ) {
      setCheckedTypes([defaultSelectedType.toUpperCase()]);
      reInitPagination();
    }
  }, [defaultSelectedType]);

  useEffect(() => {
    getGeneralNotices();
  }, [page]);

  useEffect(() => {
    if (procurements?.length) {
      setIsLoadingData(false);
    }
  }, [procurements]);

  useDidMountEffect(() => {
    reInitPagination();
  }, [searchTerm]);

  useDidMountEffect(() => {
    reInitPagination();
  }, [checkedTypes]);

  useEffect(() => {
    if (selectedItem) {
      setOpenModalData(true);
      document.body.classList.add("modal-open");
    } else {
      setOpenModalData(false);
      document.body.classList.remove("modal-open");
    }
  }, [selectedItem]);

  const getPubs = async () => {
    try {
      const payload = {
        page: 1,
        limit: 50,
      };
      const response = await GetPubs(payload);
      const { success, data, error } = response.data;
      if (success) {
        setPubs(data.pubs || []);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const getGeneralNotices = async () => {
    try {
      const payload = {
        page,
        limit,
        searchTerm,
        ...(isFieldWithValue(publicationDate.startDate) && {
          publicationStartDate: formatDateFilter(publicationDate.startDate),
        }),
        ...(isFieldWithValue(publicationDate.endDate) && {
          publicationEndDate: formatDateFilter(publicationDate.endDate),
        }),
        types: checkedTypes,
      };
      setIsLoadingData(true);
      const response = await GetAwareNotices(payload);
      const { success, data, error } = response.data;
      if (success) {
        setTotal(data.total);
        setProcurements(data.aware_notices);
        if (!data.aware_notices.length) {
          setIsLoadingData(false);
        }
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const previousPage = () => {
    if (page !== 1) {
      isMobileDevice
        ? refFilter?.current?.scrollIntoView()
        : window.scrollTo(0, 0);
      setPage(page - 1);
    }
  };

  const nextPage = () => {
    if (Math.ceil(total / limit) > page) {
      isMobileDevice
        ? refFilter?.current?.scrollIntoView()
        : window.scrollTo(0, 0);
      setPage(page + 1);
    }
  };

  const reInitPagination = () => {
    setIsLoadingData(true);
    isMobileDevice
      ? refFilter?.current?.scrollIntoView()
      : window.scrollTo(0, 0);
    if (page === 1) getGeneralNotices();
    else setPage(1);
  };

  const onPublicationDateFilterChange = (value) => {
    try {
      setPublicationDate(value);
    } catch (error) {}
  };

  const onFilterByPublicationDate = () => {
    try {
      if (isFieldWithValue(publicationDate.startDate)) {
        reInitPagination();
      }
    } catch (error) {}
  };

  const onSearchTermValueChange = (value = "") => {
    try {
      setSearchTerm(value);
    } catch (error) {
      console.log({ error });
    }
  };

  const onSelectType = (item) => {
    try {
      const values = checkedTypes || [];
      const index = values.findIndex((i) => i === item.value);
      if (index === -1) {
        values.push(item.value);
      } else {
        values.splice(index, 1);
      }
      setCheckedTypes([...values]);
    } catch (error) {}
  };

  const onSelectData = (data) => {
    try {
      setSelectedItem(data);
    } catch (error) {}
  };

  const formatFooterDetailsInfos = () => {
    try {
      const footerData = [
        {
          label: "DATE DE PUBLICATION",
          data: formatDateToDDMMYYYY(selectedItem?.publicationDate),
        },
        {
          label: "LIEU DE PUBLICATION",
          data: selectedItem?.publicationLocation,
        },
      ];
      if (selectedItem?.lots?.length > 1) {
        footerData.push({
          label: "NOMBRE DE LOTS",
          data: selectedItem.lots.length,
        });
      } else {
        footerData.push({
          label: "MONTANT DE L'OFFRE",
          data: selectedItem?.lots?.length ? selectedItem.lots[0].offerAmount?.toLocaleString("fr-FR"): selectedItem.offerAmount?.toLocaleString("fr-FR")
        });
      }
      return footerData;
    } catch (error) {
      return [];
    }
  };

  return (
    <SectionContainer
      style={{
        backgroundColor: "#EFF5F3",
        margin: "0em",
        padding:
          isMobileDevice || Boolean(width <= WindowsSizeBreakpoints.medium)
            ? "0em 0vw 1.5em 0vw"
            : "0em 8vw 1.5em 8vw",
      }}
      id="topData"
    >
      <ProcurementDataContainer
        style={{
          ...(isMobileDevice || Boolean(width <= WindowsSizeBreakpoints.medium)
            ? {
                display: "contents",
              }
            : {
                display: "grid",
              }),
        }}
      >
        <div>
          <ContainerFilterProcurement
            style={{
              ...(isMobileDevice ||
              Boolean(width <= WindowsSizeBreakpoints.medium)
                ? {
                    backgroundColor: "#FFF",
                    margin: "10px 10px 25px 10px",
                    boxShadow: "none",
                  }
                : {
                    backgroundColor: "#FFF",
                  }),
            }}
          >
            <h3
              style={{
                marginTop: "0em",
                marginBottom: "0em",
                color: "#000",
                textAlign: "center",
              }}
              ref={refFilter}
            >
              FILTRES
            </h3>
            <FilterItem>
              <CustomFilterSearch
                onSearchTerm={onSearchTermValueChange}
                placeholder="#Mot clé (autorité, ref, mode de pass.)"
              />
              <div
                style={{ marginBottom: "20px", backgroundColor: "transparent" }}
              >
                <CustomCollapse
                  options={itemsCollapse}
                  title="Type d'attribution"
                  checked={checkedTypes}
                  onClick={onSelectType}
                />
              </div>
              <ContainerFilterOption style={{ backgroundColor: "#F1F3F5" }}>
                <div style={{ textAlign: "center" }}>
                  <small
                    style={{
                      fontSize: "10pt",
                      color: "#000",
                      fontWeight: "bold",
                    }}
                  >
                    Date de publication
                  </small>
                </div>
                <CustomFilterDate
                  startDate={publicationDate.startDate}
                  endDate={publicationDate.endDate}
                  onDateChange={onPublicationDateFilterChange}
                />
                <ItemMenuOption
                  style={{
                    borderRadius: "5px",
                    backgroundColor: "#727D1F",
                    margin: "0px",
                    color: "#FFF",
                  }}
                  onClick={onFilterByPublicationDate}
                >
                  Filtrer
                </ItemMenuOption>
              </ContainerFilterOption>
            </FilterItem>
          </ContainerFilterProcurement>
          {!isMobileDevice && width > WindowsSizeBreakpoints.medium && (
            <ContainerPubs>
              <small className="pubs">Publicités</small>
              {pubs?.map((data, key) => (
                <CardPub urlImg={data.image} name={data.name} key={key} />
              ))}
            </ContainerPubs>
          )}
        </div>
        <ContainerCardsProcurement
          style={{
            ...(isMobileDevice ||
            Boolean(width <= WindowsSizeBreakpoints.medium)
              ? {
                  marginLeft: "0px",
                  marginTop: "15px",
                }
              : {
                  marginLeft: "10px",
                }),
          }}
        >
          {isLoadingData ? (
            <div
              className="in-container-loader"
              style={{ marginBottom: "100px" }}
            >
              <div className="spinner-loader"></div>
            </div>
          ) : (
            <>
              {procurements?.map((data, key) => (
                <CardAwareNotice key={key} data={data} onClick={onSelectData} />
              ))}
              {Boolean(procurements?.length) ? (
                <CustomPagination
                  page={page}
                  onNextPage={nextPage}
                  onPreviousPage={previousPage}
                  isLoadingData={isLoadingData}
                  limit={limit}
                  total={total}
                  backgroundColor="#A8B252"
                />
              ) : (
                <h5 style={{ textAlign: "center" }}>Aucun résultat !</h5>
              )}
            </>
          )}
        </ContainerCardsProcurement>
      </ProcurementDataContainer>
      <ModalCard
        isOpen={openModalData}
        onClose={() => setSelectedItem(null)}
        content={<AwareNoticeDetails data={selectedItem}/>}
        headerBackgroundColor="#468FA7"
        header={
          <HeaderCardDetails
            data={selectedItem}
            headerGridTemplateColumns="1fr 3fr 1fr"
            primaryColor="#727D1F"
            secondaryColor="#A8B252"
            textColor="#FFF"
          />
        }
        footer={
          <FooterCardDetails
            data={formatFooterDetailsInfos()} //
            footerGridTemplateColumns="repeat(3, 1fr)"
          />
        }
        marginTop="10vh"
      />
    </SectionContainer>
  );
}
