import React, { useCallback, useState } from "react";
import {
  ContentDataCardGeneralNotice,
  Flash,
  HeaderCardGeneralNoticeContainer,
  LabelCardGeneralNotice,
  ValueCardGeneralNotice,
} from "../../styles/cards";
import {
  WindowsSizeBreakpoints,
  getRemainingDays,
  isFieldWithValue,
  isMobileOrSmallScreen,
  truncateText,
} from "../../utils";
import { useWindowSize } from "../../utils/useWindowSize";

export default function HeaderCardDetails({
  data,
  headerGridTemplateColumns = "1fr 4fr",
  primaryColor,
  secondaryColor,
  textColor = "#FF",
}) {
  const [isMobileDevice, _] = useState(isMobileOrSmallScreen());
  const { width } = useWindowSize();
  const [remainingDays, setRemainingDays] = useState(
    getRemainingDays(
      data?.isDeferralNotice ? data?.referralDate : data?.limitDate
    )
  );

  const getFlashColor = useCallback(() => {
    if (remainingDays < 0) return "#FF1F00";
    if (remainingDays > 5) return "#009888";
    if (remainingDays <= 5) return "#F07349";
  }, [remainingDays]);

  const displayRemainingDay = useCallback(() => {
    if (remainingDays === null) return <div></div>;
    if (remainingDays >= 0) {
      return (
        <Flash
          color={getFlashColor()}
          style={{
            borderRadius: "0px",
            animationName: "flash-card",
            flexDirection: "column",
            color: "#FFF",
          }}
        >
          {data?.isDeferralNotice ? "Fin report" : "Dépôt offres"}
          <b>
            {remainingDays} Jr{remainingDays > 1 ? "s" : ""} restant
            {remainingDays > 1 ? "s" : ""}
          </b>
          {""}
        </Flash>
      );
    }
    return (
      <Flash
        color={getFlashColor()}
        style={{ animationName: "none", color: "white", borderRadius: "0px" }}
      >
        <b>Délai dépassé</b> &nbsp;
      </Flash>
    );
  }, [remainingDays]);

  return (
    <HeaderCardGeneralNoticeContainer
      style={{
        gridTemplateColumns: headerGridTemplateColumns,
        display:
          isMobileDevice || Boolean(width <= WindowsSizeBreakpoints.medium)
            ? "contents"
            : "grid",
      }}
    >
      <ContentDataCardGeneralNotice
        style={{
          backgroundColor: remainingDays < 0 ? "#FF1F00" : primaryColor,
        }}
      >
        <LabelCardGeneralNotice
          style={{
            color: textColor,
            fontSize: "7pt",
          }}
        >
          RÉFÉRENCE
        </LabelCardGeneralNotice>
        <ValueCardGeneralNotice
          style={{
            fontSize: "8pt",
            color: textColor,
          }}
        >
          {data?.ref}
        </ValueCardGeneralNotice>
      </ContentDataCardGeneralNotice>
      <ContentDataCardGeneralNotice
        style={{
          backgroundColor: remainingDays < 0 ? "#FFBAB1" : secondaryColor,
        }}
      >
        <LabelCardGeneralNotice style={{ color: "#FFF", fontWeight: "normal" }}>
          AUTORITÉ CONTRACTANTE
        </LabelCardGeneralNotice>
        <ValueCardGeneralNotice style={{ color: "#FFF" }}>
          {truncateText(data?.authority, 200)}
        </ValueCardGeneralNotice>
      </ContentDataCardGeneralNotice>
      {Boolean(isFieldWithValue(data?.limitDate || data?.referralDate)) &&
        displayRemainingDay()}
    </HeaderCardGeneralNoticeContainer>
  );
}
