import React from "react";
import {
  CardOpportunityContainer,
  CardOpportunityContent,
  CardOpportunityDescription,
  CardOpportunityImageContainer,
  CardOpportunityTitle,
} from "../../styles/cards";
import { truncateText } from "../../utils";
import { ItemMenuOption } from "../../styles/home";
import { Link } from "react-router-dom";

export default function CardOpportunity({ data }) {
  return (
    <Link to={`/opportunities/${data.code}`}>
      <CardOpportunityContainer>
        <CardOpportunityImageContainer>
          <img src={data.image} style={{ width: "85%" }} alt={truncateText(data.title)}/>
        </CardOpportunityImageContainer>
        <CardOpportunityContent>
          <CardOpportunityTitle>
            {truncateText(data.title)}
          </CardOpportunityTitle>
          <CardOpportunityDescription>
            {truncateText(data.description)}
          </CardOpportunityDescription>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "20px",
            }}
          >
            <ItemMenuOption
              style={{ borderRadius: "0px", backgroundColor: "#374B42" }}
              onClick={() => null}
            >
              Details
            </ItemMenuOption>
          </div>
        </CardOpportunityContent>
      </CardOpportunityContainer>
    </Link>
  );
}
